import { forwardRef, MouseEventHandler, useCallback } from "react";
import { Category } from "@/components/categories/types";
import { NavContainerCell } from "../TemplateCategoriesComponents";
import { headerOffset, scrollSpyDataAttribute } from "../constants";
import { TemplateCategoriesNavItem } from "./TemplateCategoriesNavItem";

type Props = {
    templateCategories: Category[];
    onScrollspyScroll: (offsetPosition: number) => void;
};

export const TemplateCategoriesNav = forwardRef<HTMLDivElement, Props>(
    function TemplateCategoriesNav(props, ref) {
        const onScrollToCategory = useCallback<MouseEventHandler<HTMLAnchorElement>>(
            (e) => {
                e.preventDefault();
                const targetId = e.currentTarget.dataset[scrollSpyDataAttribute];
                if (!targetId) return;
                const target = window.document.getElementById(targetId);
                if (target) {
                    const elementPosition = target.getBoundingClientRect().top;
                    const offsetPosition = elementPosition - headerOffset;

                    props.onScrollspyScroll(offsetPosition);
                }
            },
            [props],
        );

        return (
            <NavContainerCell ref={ref}>
                {props.templateCategories.map((category) => (
                    <TemplateCategoriesNavItem
                        key={category.guid}
                        category={category}
                        onScrollToCategory={onScrollToCategory}
                    />
                ))}
            </NavContainerCell>
        );
    },
);
