import { useCallback, useMemo } from "react";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import { useDeploy } from "@/hooks/useDeploy";
import { DeployResults } from "../common/Deploy/components/DeployResults";

const MessagesContainer = styled.section`
    max-height: 75vh;
    overflow: scroll;
`;

const DeployModal = create(function DeployModal() {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const { messages, deployFinished, hasErrors, deployInProgress } = useDeploy();

    return (
        <Modal
            {...dialog}
            size="lg"
            contentClassName={classNames({
                "bg-gradient-warning": hasErrors,
                "bg-gradient-success": deployFinished,
            })}
        >
            <Modal.Header>
                <Modal.Title>Deploy {deployInProgress && <Spinner size="sm" />}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <MessagesContainer>
                    <DeployResults results={messages} />
                </MessagesContainer>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={modal.hide}>Ok</Button>
            </Modal.Footer>
        </Modal>
    );
});

export function useDeployModal() {
    return useCallback(() => show(DeployModal), []);
}
