import { gql } from "@apollo/client";
import { DeployGameEventsQuery, DeployGameEventsQueryVariables } from "@/gql";
import { makeGqlQueryHook } from "../util";

// DEPLOY_EVENT_FRAGMENT здесь не используется потому что по непонятной причине поля перестают запрашиваться
const SCHEMA = gql`
    query deployGameEvents($gameId: String!) {
        deployInProgress(gameId: $gameId)
        events: deployEvents(gameId: $gameId) {
            deployCode
            error
            createdAt
            artifactId
            ... on ConsumerProcessingEvent {
                consumerId
            }
            ... on ConsumerProcessingFailedEvent {
                consumerId
            }
            ... on ConsumerProcessingFinishedEvent {
                consumerId
            }
            ... on EntityUploadEvent {
                entityName
            }
            ... on EntityUploadFailedEvent {
                entityName
            }
            ... on EntityUploadFinishedEvent {
                entityName
            }
            ... on IncorrectValueEvent {
                entityParameterName
                entityId
                entityParameterId
                value
            }
            ... on NotExportableEvent {
                entityId
            }
            ... on RequiredCheckFailedEvent {
                entityId
                entityParameterId
                entityParameterValue
            }
            ... on TemplateValidationEvent {
                templateId
                templateParameterId
            }
            ... on TemplateValidationErrorEvent {
                templateId
                templateParameterId
            }
            ... on TemplateValidationFailedEvent {
                templateId
                templateParameterId
            }
            ... on TemplateValidationMissingReferenceEvent {
                templateId
                templateParameterId
                referencedTemplateId
            }
            ... on UniqCheckFailedEvent {
                entityId
                templateId
                entityParameterId
                entityParameterValue
            }
            ... on CircularReferenceEvent {
                referenceChain
            }
            ... on DeploymentValidationFailedEvent {
                info {
                    name
                    expected
                    received
                }
            }
            ... on MissingReferencedEntityEvent {
                referencingEntityId
                entityParameterId
                entityParameterName
            }
        }
    }
`;

export const useDeployGameQuery = makeGqlQueryHook<
    DeployGameEventsQuery,
    DeployGameEventsQueryVariables
>(SCHEMA, {
    pollInterval: 3000,
    fetchPolicy: "network-only",
});
