import { CellContext } from "@tanstack/react-table";
import { TranslationData } from "../../types";

export function TranslationKeyCell(props: CellContext<TranslationData, "key">) {
    const key = props.getValue();

    return (
        <span className="small font-monospace" title={key}>
            {key}
        </span>
    );
}
