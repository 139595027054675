import { useDebounceCallback } from "usehooks-ts";
import { ChangeEvent, useCallback } from "react";
import { useSnackbar } from "notistack";
import { useUpdateTemplateCategory } from "@/graphql/templates";
import { useTemplateCategoriesProvider } from "@/components/categories/providers/TemplateCategoriesProvider";

export function useRenameCategory(gameId: string, guid: string) {
    const { enqueueSnackbar } = useSnackbar();
    const { renameCategory: renameCategoryAction, query } = useTemplateCategoriesProvider();
    const [updateTemplateCategory] = useUpdateTemplateCategory({
        onCompleted: () => query.refetch(),
    });
    const renameCategory = useCallback(
        async (newName: string) => {
            await updateTemplateCategory({
                variables: {
                    gameId,
                    guid,
                    name: newName,
                },
            });
            renameCategoryAction({
                categoryId: guid,
                newName: newName,
            });
            await query.refetch();
            enqueueSnackbar("Category renamed", {
                variant: "success",
            });
        },
        [updateTemplateCategory, gameId, guid, renameCategoryAction, query, enqueueSnackbar],
    );

    return useDebounceCallback((event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        return renameCategory(name);
    }, 300);
}
