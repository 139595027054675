import { useCallback } from "react";
import { useSnackbar } from "notistack";
import Button from "react-bootstrap/Button";
import {
    useLazyGetTranslation,
    useUpdateTranslationFromDeeplMutation,
} from "@/graphql/translation";
import { Locale } from "@/types";
import { useShowUpdateTranslationModal } from "../modals/UpdateTranslationModal";
import { useTranslationsPageContext } from "../TranslationsPageProvider";
import { Translation, TranslationData } from "../../types";

export function useEditTranslation(gameId: string, locales: Locale[]) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { translations } = useTranslationsPageContext();
    const [getTranslation] = useLazyGetTranslation();
    const showUpdateTranslationModal = useShowUpdateTranslationModal();
    const [updateTranslationFromDeepl] = useUpdateTranslationFromDeeplMutation();

    const onUpdateTranslationFromDeepl = useCallback(
        async (row: Translation) => {
            await updateTranslationFromDeepl({
                variables: {
                    guid: row.guid,
                    gameId: row.gameId,
                },
            });
            await translations.refetch();
        },
        [translations, updateTranslationFromDeepl],
    );

    const promptForUpdateTranslation = useCallback(
        (row: Translation) => {
            enqueueSnackbar(
                "Base translation was changed. Do you wish to update rest of them with translation from Deepl?",
                {
                    variant: "success",
                    action: (snackbarId) => (
                        <>
                            <Button
                                size="sm"
                                onClick={async () => {
                                    await onUpdateTranslationFromDeepl(row);
                                    closeSnackbar(snackbarId);
                                }}
                            >
                                Update
                            </Button>
                            <Button size="sm" onClick={() => closeSnackbar(snackbarId)}>
                                Dismiss
                            </Button>
                        </>
                    ),
                },
            );
        },
        [closeSnackbar, enqueueSnackbar, onUpdateTranslationFromDeepl],
    );

    return useCallback(
        async (row: TranslationData) => {
            const { data } = await getTranslation({
                variables: {
                    guid: row.guid,
                    gameId: row.gameId,
                },
            });
            if (!data?.translation) {
                enqueueSnackbar("Couldn't find translation entry", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
                return;
            }
            const { baseTranslationChanged } = await showUpdateTranslationModal({
                gameId,
                locales,
                translation: data.translation,
            });

            if (baseTranslationChanged) {
                promptForUpdateTranslation(data.translation);
            }
            return translations.refetch();
        },
        [
            enqueueSnackbar,
            gameId,
            getTranslation,
            locales,
            promptForUpdateTranslation,
            showUpdateTranslationModal,
            translations,
        ],
    );
}
