import Stack from "react-bootstrap/Stack";
import cn from "classnames";
import { useAuth } from "@/hooks/useAuth";
import { UserInfoAsync } from "./UserInfoAsync";

type Props = {
    lockReason: string;
    lockedBy: string;
    className?: string;
};

export function TemplateLockedBy(props: Props) {
    const { user } = useAuth();

    return (
        <Stack className={cn("text-start", props.className)}>
            <div>
                <span className="fw-bold">Locked by:</span>
                <br />
                {user && props.lockedBy === user.guid ? (
                    "You"
                ) : (
                    <UserInfoAsync userId={props.lockedBy} />
                )}
            </div>
            <div>
                <span className="fw-bold">Reason:</span>
                <br />
                {props.lockReason}
            </div>
        </Stack>
    );
}
