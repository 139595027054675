import { useMemo } from "react";
import { useDraggable } from "@dnd-kit/core";
import cn from "classnames";
import { FaGripVertical } from "react-icons/fa";
import { CSS } from "@dnd-kit/utilities";
import { IconButton } from "@/components/common/IconButton";
import { Template } from "@/types";
import { CategoryTemplate } from "./CategoryTemplate";

type Props = {
    template: Template;
    className?: string;
};

export function MovableTemplate(props: Props) {
    const { isDragging, setNodeRef, attributes, listeners, transform, over } = useDraggable({
        id: props.template.guid,
        data: props.template,
    });
    const style = useMemo(() => ({ transform: CSS.Translate.toString(transform) }), [transform]);
    const className = useMemo(
        () =>
            cn(props.className, {
                "border-primary": isDragging,
                "opacity-75": isDragging,
                "z-3": isDragging,
                "z-2": over,
            }),
        [isDragging, over, props.className],
    );

    return (
        <CategoryTemplate
            template={props.template}
            className={className}
            style={style}
            ref={setNodeRef}
            end={
                <IconButton
                    variant="link"
                    icon={FaGripVertical}
                    width={1.5}
                    {...attributes}
                    {...listeners}
                />
            }
        />
    );
}
