import { createContext, useContext } from "react";
import { Locale } from "@/types";

type TranslationFieldContext = {
    index: number;
    locale: Locale;
};

export const TranslationFieldContext = createContext<TranslationFieldContext>(undefined!);

export function useTranslationFieldContext() {
    return useContext(TranslationFieldContext);
}
