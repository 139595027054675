import { useCallback } from "react";
import { useController, useFieldArray, useForm } from "react-hook-form";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Stack from "react-bootstrap/Stack";
import { useSnackbar } from "notistack";
import Badge from "react-bootstrap/Badge";
import { CreateTranslationMutationVariables } from "@/gql";
import {
    useCheckTranslationKeyForUniqueness,
    useCreateTranslationMutation,
} from "@/graphql/translation";
import { Locale } from "@/types";

type Props = {
    gameId: string;
    locales: Locale[];
};

type FormData = {
    gameId: string;
    key: string;
    description: string;
    locStrings: Array<Locale & { value: string; isProtected: boolean }>;
};

function CreateTranslationModalComponent(props: Props) {
    const { gameId, locales } = props;
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const { enqueueSnackbar } = useSnackbar();
    const [createTranslationMutation, { loading: createTranslationMutationLoading }] =
        useCreateTranslationMutation();
    const [checkTranslationKeyForUniqueness, { loading: checkTranslationKeyForUniquenessLoading }] =
        useCheckTranslationKeyForUniqueness();
    const loading = checkTranslationKeyForUniquenessLoading || createTranslationMutationLoading;
    const form = useForm<FormData>({
        mode: "onChange",
        defaultValues: {
            gameId,
            key: "",
            description: "",
            locStrings: locales.map((l) => ({ ...l, value: "", isProtected: false })),
        },
    });
    const keyController = useController({
        control: form.control,
        name: "key",
        rules: {
            min: 1,
            max: 255,
            required: { value: true, message: "Translation key is required" },
            validate: async (key) => {
                const result = await checkTranslationKeyForUniqueness({
                    variables: { gameId, key },
                });

                if (!result.data?.checkTranslationKeyForUniqueness.isOk) {
                    return "This key already exists";
                }
            },
        },
    });
    const descriptionController = useController({
        control: form.control,
        name: "description",
        rules: {
            max: 1024,
        },
    });
    const localesArray = useFieldArray({
        control: form.control,
        name: "locStrings",
    });
    const onSubmit = useCallback(
        async (data: FormData) => {
            const variables: CreateTranslationMutationVariables = {
                gameId: data.gameId,
                key: data.key,
                description: data.description,
                locStrings: data.locStrings.map((locStr) => ({
                    value: locStr.value,
                    localeId: locStr.guid,
                    isProtected: locStr.isProtected,
                })),
            };
            const { errors } = await createTranslationMutation({ variables });
            if (!errors) {
                enqueueSnackbar("Translation created", {
                    variant: "success",
                });
                modal.resolve();
                return modal.hide();
            } else {
                console.dir(errors);
                enqueueSnackbar("Couldn't create translation", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [createTranslationMutation, modal, enqueueSnackbar],
    );

    return (
        <Modal {...dialog}>
            <Modal.Header>
                <Modal.Title>Create translation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={form.handleSubmit(onSubmit)}>
                    <Stack gap={4}>
                        <Form.Group>
                            <Form.Label>Translation key</Form.Label>
                            <Form.Control
                                {...keyController.field}
                                isInvalid={!!descriptionController.formState.errors.key}
                            />
                            {keyController.formState.errors.key && (
                                <Form.Control.Feedback type="invalid">
                                    {keyController.formState.errors.key?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                {...descriptionController.field}
                                isInvalid={!!descriptionController.formState.errors.description}
                                as="textarea"
                            />
                            {descriptionController.formState.errors.description && (
                                <Form.Control.Feedback type="invalid">
                                    {descriptionController.formState.errors.description?.message}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        {localesArray.fields.map((locale, index) => (
                            <Form.Group key={locale.id}>
                                <Form.Label className="d-flex align-items-center gap-1 mb-1">
                                    <span>
                                        {locale.name} ({locale.code})
                                    </span>
                                    {locale.isBaseLocale && <Badge>Base locale</Badge>}
                                </Form.Label>
                                <Form.Control
                                    {...form.register(`locStrings.${index}.value`, {
                                        required: {
                                            value: locale.isBaseLocale,
                                            message: "Base translation is required",
                                        },
                                    })}
                                    isInvalid={Boolean(
                                        form.formState.errors.locStrings?.[index]?.value,
                                    )}
                                />
                                {form.formState.errors.locStrings?.[index]?.value?.message && (
                                    <Form.Control.Feedback type="invalid">
                                        {form.formState.errors.locStrings[index]!.value!.message}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        ))}
                        <Button type="submit" disabled={loading || !form.formState.isValid}>
                            {loading && <Spinner size="sm" />}
                            Create
                        </Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

const CreateTranslationModal = create<Props>(CreateTranslationModalComponent);

export function showCreateTranslationModal(props: Props): Promise<void> {
    return show(CreateTranslationModal, props);
}
