import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { QueryResult } from "@apollo/client";
import sortBy from "lodash/sortBy";
import { useTemplateCategories } from "@/graphql/templates";
import { TemplateCategoriesQuery, TemplateCategoriesQueryVariables } from "@/gql";
import {
    ActionWithoutType,
    DeleteCategoryAction,
    MoveTemplateAction,
    ReducerAction,
    RenameCategoryAction,
    SetCollectionAction,
    useTemplateCategoriesReducer,
} from "../hooks/useTemplateCategoriesReducer";
import { Category } from "../types";
import { DataAwaiter2 } from "../../common/DataAwaiter2";

type TemplateCategoriesProvider = {
    categories: Category[];
    setCollection: (data: ActionWithoutType<SetCollectionAction>) => void;
    moveTemplate: (data: ActionWithoutType<MoveTemplateAction>) => void;
    deleteCategory: (data: ActionWithoutType<DeleteCategoryAction>) => void;
    renameCategory: (data: ActionWithoutType<RenameCategoryAction>) => void;
    query: QueryResult<TemplateCategoriesQuery, TemplateCategoriesQueryVariables>;
};

const context = createContext<TemplateCategoriesProvider>(undefined!);

export function TemplateCategoriesProvider(props: PropsWithChildren<{ gameId: string }>) {
    const { children, gameId } = props;
    const [state, dispatch] = useTemplateCategoriesReducer([]);
    const templateCategories = useTemplateCategories({
        variables: { gameId },
        refetchWritePolicy: "overwrite",
        fetchPolicy: "cache-and-network",
        // Bullshit workaround to trigger onCompleted after refetch somewhere
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            dispatch({
                type: ReducerAction.SetCollection,
                categories: data.templateCategories,
            });
        },
    });
    const value = useMemo<TemplateCategoriesProvider>(
        () => ({
            categories: sortBy(state.categories, "name").map((c) => ({
                ...c,
                templates: sortBy(c.templates, "name"),
            })),
            setCollection: (data: ActionWithoutType<SetCollectionAction>) => {
                dispatch({
                    ...data,
                    type: ReducerAction.SetCollection,
                });
            },
            moveTemplate: (data: ActionWithoutType<MoveTemplateAction>) => {
                dispatch({
                    ...data,
                    type: ReducerAction.MoveTemplate,
                });
            },
            deleteCategory: (data: ActionWithoutType<DeleteCategoryAction>) => {
                dispatch({
                    ...data,
                    type: ReducerAction.DeleteCategory,
                });
            },
            renameCategory: (data: ActionWithoutType<RenameCategoryAction>) => {
                dispatch({
                    ...data,
                    type: ReducerAction.RenameCategory,
                });
            },
            query: templateCategories,
        }),
        [dispatch, state.categories, templateCategories],
    );

    return (
        <context.Provider value={value}>
            <DataAwaiter2 {...templateCategories}>{() => children}</DataAwaiter2>
        </context.Provider>
    );
}

export function useTemplateCategoriesProvider() {
    return useContext(context);
}
