import { useCallback } from "react";
import { MdTranslate } from "react-icons/md";
import {
    useCheckTranslationKeyForUniqueness,
    useDeeplCreateTranslationMutation,
} from "@/graphql/translation";
import { ProgressButton } from "../../../common/ProgressButton";
import { showCreateDeeplTranslationModal } from "../modals/CreateDeeplTranslationModal";
import { useTranslationsPageContext } from "../TranslationsPageProvider";

export function CreateFromDeeplTranslationButton() {
    const { translations, gameId } = useTranslationsPageContext();
    const [, { loading: createDeeplTranslationMutationLoading }] =
        useDeeplCreateTranslationMutation();
    const [, { loading: checkTranslationKeyForUniquenessLoading }] =
        useCheckTranslationKeyForUniqueness();
    const loading =
        createDeeplTranslationMutationLoading ||
        checkTranslationKeyForUniquenessLoading ||
        translations.loading;

    const onCreateDeeplTranslation = useCallback(async () => {
        await showCreateDeeplTranslationModal({
            gameId,
            locales: (await translations.observable.result()).data.locales,
        });
        return translations.refetch();
    }, [gameId, translations]);

    return (
        <ProgressButton onClick={onCreateDeeplTranslation} loading={loading}>
            <MdTranslate /> Create from Deepl
        </ProgressButton>
    );
}
