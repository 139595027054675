import { useMemo } from "react";
import { useToggle } from "usehooks-ts";
import Stack from "react-bootstrap/Stack";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import cn from "classnames";
import { useDroppable } from "@dnd-kit/core";
import { IconButton } from "@/components/common/IconButton";
import { Template, TemplateCategory } from "@/types";
import { useRenameCategory } from "../hooks/useRenameCategory";
import { DeleteCategoryButton } from "./DeleteCategoryButton";
import { MovableTemplate } from "./MovableTemplate";

type Props = {
    className?: string;
    data: TemplateCategory & { templates: Template[] };
    onItemSelected?: () => void;
};

export function EditableTemplatesCategory(props: Props) {
    const onNameChange = useRenameCategory(props.data.gameId, props.data.guid);
    const { isOver, setNodeRef } = useDroppable({
        id: props.data.guid,
        data: props.data,
    });
    const [isExpanded, toggleExpanded] = useToggle(true);
    const templates = props.data.templates;
    const categoryClassName = useMemo(
        () =>
            cn("border-1 rounded position-relative", props.className, {
                "border-primary": isOver,
                border: isOver,
                "z-1": isOver,
                "border-white": !isOver,
            }),
        [props.className, isOver],
    );
    return (
        <Stack
            as="section"
            className={categoryClassName}
            style={{ borderStyle: isOver ? "dashed !important" : "unset" }}
            ref={setNodeRef}
        >
            <div className="fs-6 lh-lg px-3 py-1 d-flex align-items-center gap-2 bg-secondary">
                <IconButton
                    width={1.5}
                    icon={isExpanded ? FaCaretDown : FaCaretUp}
                    onClick={toggleExpanded}
                />
                <Form.Control
                    className="flex-grow-1 form-control-plaintext p-0 lh-lg fs-6"
                    defaultValue={props.data.name}
                    onChange={onNameChange}
                />
                <DeleteCategoryButton category={props.data} />
            </div>
            {isExpanded && (
                <ListGroup>
                    {templates.map((template) => (
                        <MovableTemplate key={template.guid} template={template} />
                    ))}
                </ListGroup>
            )}
        </Stack>
    );
}
