import Alert from "react-bootstrap/Alert";
import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { withRBAC } from "@/components/rbac";
import { Permission } from "@/gql";
import { HistoryPage } from "@/components/history/HistoryPage";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

export function ProjectHistory() {
    const { useParams } = projectHistory;
    const { gameId } = useParams();

    if (!gameId) return <Alert>missing gameId parameter</Alert>;

    return (
        <>
            <Helmet title="History" />
            <HistoryPage gameId={gameId} />
        </>
    );
}

const ProjectHistoryRBAC = withRBAC(ProjectHistory, {
    requiredPermissions: [Permission.DataRead, Permission.StructureRead],
    oneOf: true,
});
export default ProjectHistoryRBAC;
export const projectHistory = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "history",
    component: ProjectHistoryRBAC,
});
