import Badge from "react-bootstrap/Badge";
import { Locale } from "@/types";

type Props = {
    locale: Locale;
};

export function TranslationCellHeader(props: Props) {
    const { locale } = props;

    return (
        <div className="d-flex gap-1">
            {locale.name} ({locale.code}){locale.isBaseLocale && <Badge>Base locale</Badge>}
        </div>
    );
}
