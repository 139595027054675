import { useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import type { FieldPath, FieldValues } from "react-hook-form/dist/types";
import type { FormControlProps } from "react-bootstrap";

type ReactFormConnectionProps<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    name: TName;
    rules?: any; // Actual type is RegisterOptions<TFieldValues, TName>;
};

type FormInputProps = {
    label?: string;
    step?: number;
    min?: number;
    max?: number;
    required?: boolean;
};

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ReactFormConnectionProps<TFieldValues, TName> & FormInputProps & FormControlPickedProps;

type FormControlPickedProps = Pick<FormControlProps, "type" | "as" | "disabled" | "className">;

export function FormInput2<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: Props<TFieldValues, TName>) {
    const { name, rules } = props;
    const form = useFormContext<TFieldValues>();
    const { field, fieldState } = useController<TFieldValues, TName>({
        control: form.control,
        name,
        rules,
    });
    const error = useMemo(() => {
        if (!fieldState.error) return null;
        switch (fieldState.error.type) {
            case "required":
                return "This field is required";
            default:
                return fieldState.error.message || "Invalid value";
        }
    }, [fieldState.error]);

    return (
        <Form.Group className={props.className}>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <Form.Control
                {...field}
                step={props.step}
                min={props.min}
                max={props.max}
                type={props.type}
                as={props.as}
                isInvalid={fieldState.error !== undefined}
                placeholder={!props.label ? props.name : undefined}
            />
            {props.required && (
                <Col md={12}>
                    <Form.Text muted>Required</Form.Text>
                </Col>
            )}
            {error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
        </Form.Group>
    );
}
