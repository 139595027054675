import { FormEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import sortBy from "lodash/sortBy";
import { Link } from "@tanstack/react-router";
import { TemplateCategoriesQuery } from "@/gql";
import { categoriesPageRoute } from "@/pages/CategoriesPage";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { useFilterCategories } from "@/components/categories/hooks/useFilterCategories";
import { FavoriteTemplatesCategory } from "./components/FavoriteTemplatesCategory";
import { CategoriesSidebarContextProvider } from "./components/CategoriesSidebarContext";
import { TemplateCategoriesNav } from "./components/TemplateCategoriesNav";
import {
    ScrollSpyContainer,
    TemplateCategoriesWrapper,
    FilterInputCell,
    ManageCategoriesCell,
    CategoriesWrapperCell,
} from "./TemplateCategoriesComponents";
import { SidebarTemplatesCategory } from "./components/SidebarTemplatesCategory";

type Props = {
    className?: string;
    templateCategories: TemplateCategoriesQuery["templateCategories"];
    onItemSelected: () => void;
};

export function TemplateCategoriesSidebar(props: Props) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const filterRef = useRef<HTMLInputElement | null>(null);
    const [filterText, setFilterText] = useState("");
    const filter = useMemo(() => filterText.trim().toLowerCase(), [filterText]);
    const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);
    const navContainerRef = useRef<HTMLDivElement | null>(null);
    const onFilterEvent = useCallback<FormEventHandler<HTMLInputElement>>(
        (event) => setFilterText(event.currentTarget.value),
        [],
    );
    const [contains, exact] = useFilterCategories(filterText, props.templateCategories);
    const onScrollspyScroll = useCallback((offsetPosition: number) => {
        parentScrollContainerRef.current?.scrollBy({
            top: offsetPosition,
            behavior: "smooth",
        });
    }, []);
    const templateCategories = useMemo(() => {
        return props.templateCategories
            .map((tc) => {
                return {
                    ...tc,
                    templates: sortBy(
                        tc.templates.filter(
                            (template) =>
                                filter.length < 2 || template.name.toLowerCase().includes(filter),
                        ),
                        "name",
                    ),
                    scrollspyId: `category-${tc.name.replace(/\W/, "")}`,
                };
            })
            .filter((tc) => tc.templates.length > 0);
    }, [filter, props.templateCategories]);

    useEffect(() => {
        filterRef.current?.focus();
    }, []);

    return (
        <TemplateCategoriesWrapper className={props.className}>
            <FilterInputCell>
                <Form.Control
                    ref={filterRef}
                    defaultValue={filterText}
                    onInput={onFilterEvent}
                    placeholder="Filter"
                />
            </FilterInputCell>
            <ManageCategoriesCell className="w-100 text-end">
                <Link
                    from={layoutWithBreadcrumbs.fullPath}
                    to={categoriesPageRoute.fullPath}
                    className="small"
                    onClick={props.onItemSelected}
                >
                    Manage categories
                </Link>
            </ManageCategoriesCell>
            <CategoriesWrapperCell ref={parentScrollContainerRef}>
                <CategoriesSidebarContextProvider gameId={gameId}>
                    <ScrollSpyContainer
                        useBoxMethod
                        navContainerRef={navContainerRef}
                        parentScrollContainerRef={parentScrollContainerRef}
                        updateHistoryStack={false}
                        scrollThrottle={300}
                        offsetTop={120}
                    >
                        {exact.templates.length > 0 && (
                            <>
                                <div className="px-3 py-1 lh-lg fw-bold">Exact match:</div>
                                <SidebarTemplatesCategory
                                    data={exact}
                                    className="mb-4"
                                    onItemSelected={props.onItemSelected}
                                />
                            </>
                        )}
                        <FavoriteTemplatesCategory
                            templateCategories={templateCategories}
                            onItemSelected={props.onItemSelected}
                        />
                        {exact.templates.length > 0 && (
                            <div className="px-3 py-1 lh-lg fw-bold">Contains:</div>
                        )}
                        {contains.map((category) => (
                            <SidebarTemplatesCategory
                                className="mb-4"
                                key={category.guid}
                                data={category}
                                onItemSelected={props.onItemSelected}
                            />
                        ))}
                    </ScrollSpyContainer>
                </CategoriesSidebarContextProvider>
            </CategoriesWrapperCell>
            <TemplateCategoriesNav
                ref={navContainerRef}
                templateCategories={templateCategories}
                onScrollspyScroll={onScrollspyScroll}
            />
        </TemplateCategoriesWrapper>
    );
}
