import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { Link, createRoute } from "@tanstack/react-router";
import { FaTrash } from "react-icons/fa";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Helmet } from "react-helmet-async";
import { createColumnHelper } from "@tanstack/react-table";
import Form from "react-bootstrap/Form";
import { useDebounceCallback } from "usehooks-ts";
import { useHasPermissions, withRBAC } from "@/components/rbac";
import { useGameDetails } from "@/store/games/slice";
import { useRemoveTemplateAction } from "@/store/games/actions";
import { CreateTemplateButton } from "@/components/common/CreateTemplateButton";
import { Permission, Template as GqlTemplate } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { entitiesListRoute } from "@/pages/EntitiesList";
import { DataTable2 } from "@/components/common/DataTable2";
import { TemplateLockedBy } from "@/components/common/TemplateLockedBy";

type Template = Pick<
    GqlTemplate,
    "guid" | "name" | "description" | "type" | "lockedBy" | "lockReason"
>;

const columnHelper = createColumnHelper<Template>();

export function TemplatesList() {
    const { useParams } = templatesListRoute;
    const { gameId } = useParams();
    const [filter, setFilter] = useState("");
    const gameInfo = useGameDetails(gameId);
    const [removeTemplateAction] = useRemoveTemplateAction();
    const onFilterChange = useDebounceCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => setFilter(e.target.value),
        300,
    );
    const templatesList = useMemo(() => {
        if (filter === "") return gameInfo.templates;

        const filterValue = filter.toLowerCase();
        return gameInfo.templates.filter(
            (template) =>
                template.name.toLowerCase().includes(filterValue) ||
                template.guid.includes(filterValue),
        );
    }, [filter, gameInfo.templates]);
    const canEditTemplates = useHasPermissions([Permission.StructureWrite]);

    const removeTemplate = useCallback(
        (guid: string) => {
            return removeTemplateAction({ guid, gameId: gameId! });
        },
        [gameId, removeTemplateAction],
    );

    const columns = useMemo(() => {
        return [
            columnHelper.accessor("name", {
                header: "Name",
                minSize: 300,
                cell: (props) => (
                    <Link
                        to={entitiesListRoute.to}
                        params={{ gameId, templateId: props.row.original.guid }}
                    >
                        {props.row.original.name}
                    </Link>
                ),
            }),
            columnHelper.accessor("description", {
                header: "Description",
                minSize: 600,
                cell: (props) => (
                    <div className="text-wrap">{props.getValue() ?? "No description"}</div>
                ),
            }),
            columnHelper.accessor("type", { header: "Type", minSize: 300 }),
            columnHelper.accessor("lockedBy", {
                header: "Is locked",
                minSize: 200,
                cell: (props) => {
                    const { lockedBy, lockReason } = props.row.original;

                    if (lockedBy && lockReason)
                        return (
                            <TemplateLockedBy
                                className="small"
                                lockedBy={lockedBy}
                                lockReason={lockReason}
                            />
                        );
                    return null;
                },
            }),
            columnHelper.accessor("guid", {
                header: "Actions",
                size: 50,
                cell: (props) => {
                    const guid = props.getValue();

                    return (
                        <ButtonGroup>
                            <ConfirmationButton
                                prompt="Are you sure you want to delete this template?"
                                color="danger"
                                size="sm"
                                disabled={!canEditTemplates}
                                confirmationPhrase={guid}
                                onConfirm={() => removeTemplate(guid)}
                            >
                                <FaTrash className="align-middle" />
                            </ConfirmationButton>
                        </ButtonGroup>
                    );
                },
            }),
        ];
    }, [canEditTemplates, gameId, removeTemplate]);

    return (
        <>
            <Helmet title="Templates" />
            <div className="d-flex align-items-center gap-2 position-sticky top-0 z-1 bg-white p-1 m--1">
                <Form.Control
                    type="search"
                    placeholder="Filter..."
                    defaultValue={filter}
                    onChange={onFilterChange}
                />
                <CreateTemplateButton gameId={gameId} />
            </div>

            <DataTable2 data={templatesList} columns={columns} />
        </>
    );
}

const TemplatesListRBAC = withRBAC(TemplatesList, {
    requiredPermissions: [Permission.StructureRead, Permission.StructureWrite],
    oneOf: true,
});
export default TemplatesListRBAC;
export const templatesListRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "templates",
    component: TemplatesListRBAC,
});
