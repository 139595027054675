import { ChangeEventHandler, useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import ToggleButton from "react-bootstrap/ToggleButton";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useGetTemplate } from "@/graphql/templates";
import { ExportFormProps } from "./types";
import { TemplateSelect } from "./TemplateSelect";

type Props = {
    disabled?: boolean;
};

export function ExportTemplates(props: Props) {
    const { getValues, setValue } = useFormContext<ExportFormProps>();
    const [gameId, templateId] = getValues(["gameId", "templateId"]);
    const template = useGetTemplate({ gameId, guid: templateId });

    const [shouldSelectAll, setShouldSelectAll] = useState(false);
    const onToggleSelectAll = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => {
            const checked = event.target.checked;
            const templateData = template.data?.template;
            if (!templateData) return;

            setShouldSelectAll(checked);
            templateData.templateParams?.map((tp) => {
                setValue(`templates.${templateData.guid}.${tp.guid}`, checked);
            });
        },
        [setValue, template.data?.template],
    );

    return (
        <DataAwaiter2 {...template}>
            {({ template }) => (
                <section>
                    <small>Select templates and fields to export</small>
                    <div>
                        <ToggleButton
                            size="sm"
                            type="checkbox"
                            id={"select-all-params"}
                            value={"select-all-params"}
                            name={"select-all-params"}
                            checked={shouldSelectAll}
                            onChange={onToggleSelectAll}
                        >
                            Select all
                        </ToggleButton>
                    </div>
                    <TemplateSelect
                        disabled={props.disabled}
                        templateId={template.guid}
                        shouldSelectAll={shouldSelectAll}
                    />
                </section>
            )}
        </DataAwaiter2>
    );
}
