import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { FaFile } from "react-icons/fa";
import { showCreateTranslationModal } from "../modals/CreateTranslationModal";
import { useTranslationsPageContext } from "../TranslationsPageProvider";

export function CreateTranslationButton() {
    const { translations, gameId } = useTranslationsPageContext();
    const onCreateTranslation = useCallback(async () => {
        await showCreateTranslationModal({
            gameId,
            locales: (await translations.observable.result()).data.locales,
        });
        return translations.refetch();
    }, [gameId, translations]);

    return (
        <Button onClick={onCreateTranslation}>
            <FaFile /> Create
        </Button>
    );
}
