import { useMemo } from "react";
import { Link as NavLink } from "@tanstack/react-router";
import {
    DeployStep,
    TemplateValidationEvent,
    TemplateValidationErrorEvent,
    TemplateValidationFailedEvent,
    TemplateValidationMissingReferenceEvent,
} from "@/gql";
import { useGetTemplate } from "@/graphql/templates";
import { templateStructureRoute } from "@/pages/template/TemplateStructure";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

export interface TemplateValidationEventRendererProps {
    gameId: string;
    event:
        | TemplateValidationEvent
        | TemplateValidationErrorEvent
        | TemplateValidationFailedEvent
        | TemplateValidationMissingReferenceEvent;
}

export function TemplateValidationEventRenderer(props: TemplateValidationEventRendererProps) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const getTemplate = useGetTemplate({ guid: props.event.templateId, gameId: props.gameId });
    const template = getTemplate.data?.template;
    const entityInfo = useMemo(() => {
        if (!template) return { templateName: null, parameterName: null };
        const templateParameter = template.templateParams?.find(
            (templateParameter) => templateParameter.guid === props.event.templateParameterId,
        );

        return {
            templateName: template.name,
            parameterName: templateParameter?.name,
        };
    }, [props.event.templateParameterId, template]);
    const referencingTemplate = (
        <NavLink
            to={templateStructureRoute.to}
            params={{ gameId, templateId: props.event.templateId }}
            target="_blank"
        >
            {entityInfo.templateName}
        </NavLink>
    );
    const referencedTemplateId = (props.event as TemplateValidationMissingReferenceEvent)
        .referencedTemplateId;

    switch (props.event.deployCode) {
        case DeployStep.TemplateValidation:
            return (
                <span>
                    Validating template parameter {entityInfo.parameterName} of{" "}
                    {referencingTemplate}
                </span>
            );
        case DeployStep.TemplateValidationError:
            return (
                <span>
                    Error happened during parameter validation {entityInfo.parameterName} of{" "}
                    {referencingTemplate}. Error: {props.event.error}
                </span>
            );
        case DeployStep.TemplateValidationFailed:
            return (
                <span>
                    Validation failed for parameter {entityInfo.parameterName} of{" "}
                    {referencingTemplate}. Error: {props.event.error}
                </span>
            );
        case DeployStep.TemplateValidationMissingReference:
            return (
                <span>
                    Parameter {entityInfo.parameterName} of
                    {referencingTemplate}
                    references
                    {referencedTemplateId ? (
                        <NavLink
                            to={templateStructureRoute.to}
                            params={{ gameId, templateId: referencedTemplateId }}
                            target="_blank"
                        >
                            a template
                        </NavLink>
                    ) : (
                        "an unknown template"
                    )}
                    not included in to the scope of current consumer. Error: {props.event.error}
                </span>
            );
        default:
            return <span>Incorrect template validation event code</span>;
    }
}
