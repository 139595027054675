import { Link } from "@tanstack/react-router";
import { RequiredCheckFailedEvent } from "@/gql";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { entityPageRoute } from "@/pages/EntityPage";

interface RequiredCheckFailedEventRendererProps {
    event: RequiredCheckFailedEvent;
}

export function RequiredCheckFailedEventRenderer(props: RequiredCheckFailedEventRendererProps) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();

    return (
        <Link to={entityPageRoute.to} params={{ gameId, entityId: props.event.entityId }}>
            {props.event.error}
        </Link>
    );
}
