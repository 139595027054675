import { useLazyGetEntitiesQuery, useRemoveEntityMutation } from "@/graphql/entities";
import { EntityParameterChangeV2 } from "@/types";
import { AppDispatch } from "@/store";
import { RemoveEntityMutationVariables } from "@/gql";
import { useWrapAction } from "@/store/hooks";
import { useUpdateTemplateEntitiesV2Mutation } from "@/graphql/entities/updateTemplateEntitiesV2.mutation";
import {
    CLEAR_ENTITIES_ERROR,
    REMOVE_ENTITY,
    REPLACE_ENTITY,
    SET_ENTITIES,
    SET_ENTITIES_ERROR,
    START_ENTITIES_LOADING,
    STOP_ENTITIES_LOADING,
} from "./slice";

export function useDeleteEntityAction() {
    const [removeEntityMutation] = useRemoveEntityMutation();

    return useWrapAction<RemoveEntityMutationVariables>(
        async (dispatch: AppDispatch, { guid, gameId, force }) => {
            await removeEntityMutation({ variables: { guid, gameId, force } });
            dispatch(REMOVE_ENTITY({ guid }));
        },
    );
}

export function useChangeEntityActionV2() {
    const [updateTemplateEntitiesV2Mutation] = useUpdateTemplateEntitiesV2Mutation();

    return useWrapAction<{
        gameId: string;
        templateId: string;
        entityChanges: Record<string, boolean>;
        entityParameterChanges: EntityParameterChangeV2[];
    }>(async (dispatch: AppDispatch, changes) => {
        const { data } = await updateTemplateEntitiesV2Mutation({
            variables: {
                gameId: changes.gameId,
                templateId: changes.templateId,
                entityChanges: Object.entries(changes.entityChanges).map(([key, value]) => ({
                    entityId: key,
                    export: value,
                })),
                entityParametersChange: changes.entityParameterChanges,
            },
        });

        if (!data) {
            throw new Error("Could not save changes");
        }

        data.result.forEach((newEntity) => {
            dispatch(REPLACE_ENTITY(newEntity));
        });
    });
}

export function useLoadEntities() {
    const [getEntitiesQuery] = useLazyGetEntitiesQuery();
    return useWrapAction(
        async (dispatch: AppDispatch, variables: { templateId: string; gameId: string }) => {
            try {
                dispatch(START_ENTITIES_LOADING());
                dispatch(CLEAR_ENTITIES_ERROR());
                const { data, error } = await getEntitiesQuery({ variables });
                if (error) {
                    throw error;
                }
                dispatch(SET_ENTITIES(data!.result));
            } catch (e) {
                dispatch(SET_ENTITIES_ERROR(e as Error));
            } finally {
                dispatch(STOP_ENTITIES_LOADING());
            }
        },
    );
}
