import { useCallback, useMemo } from "react";
import { useSessionStorage } from "usehooks-ts";
import { Template } from "@/gql";
import { entriesPerPageOptions } from "@/constants";

export function usePagination(template: Template) {
    const itemsPerPageKey = `itemsPerPage.${template.guid}`;
    const pageKey = `page.${template.guid}`;
    const [itemsPerPage, setItemsPerPage] = useSessionStorage<number>(
        itemsPerPageKey,
        template.templateDisplay?.entitiesPerPage ?? entriesPerPageOptions[0]!,
    );
    const [page, setPage] = useSessionStorage<number>(pageKey, 1);
    const onPageChange = useCallback(
        (page: number) => {
            return setPage(page);
        },
        [setPage],
    );
    const onNextPage = useCallback(() => {
        return onPageChange(page + 1);
    }, [onPageChange, page]);
    const onPrevPage = useCallback(() => {
        return onPageChange(page - 1);
    }, [onPageChange, page]);
    const onRowsPerPageChange = useCallback(
        (newItemsPerPage: number) => {
            const newPage = Math.max(1, Math.floor((itemsPerPage * (page - 1)) / newItemsPerPage));
            setPage(newPage);
            setItemsPerPage(newItemsPerPage);
        },
        [itemsPerPage, page, setItemsPerPage, setPage],
    );

    return useMemo(
        () => ({
            page,
            itemsPerPage,
            onPageChange,
            onNextPage,
            onPrevPage,
            onRowsPerPageChange,
            setPage,
        }),
        [itemsPerPage, onNextPage, onPageChange, onPrevPage, onRowsPerPageChange, page, setPage],
    );
}
