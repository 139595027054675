import { useCallback, useEffect, useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import { OnChangeValue } from "react-select";
import { stubFalse } from "lodash";
import { useGetHistoryEntries } from "@/graphql/history";
import { HistoryAction, UserFragment } from "@/gql";
import { enumToSelectOption } from "@/utils/enumToSelectOption";
import { usePagination } from "@/hooks/usePagination";
import { UserSelect } from "@/components/common/UserSelect";
import { projectHistory } from "@/pages/ProjectHistory";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { Select } from "../common/Select";
import { HistoryEntry } from "./HistoryEntry";

type Props = {
    gameId: string;
};

export function HistoryPage(props: Props) {
    const { useParams } = projectHistory;
    const { gameId } = useParams();
    const [actionTypes, setActionTypes] = useState<HistoryAction[]>([]);
    const selectedActionTypes = useMemo(() => {
        return actionTypes.map((v) => ({ value: v, label: v }));
    }, [actionTypes]);
    const [afterDate, setAfterDate] = useState<Date | undefined>(undefined);
    const [beforeDate, setBeforeDate] = useState<Date | undefined>(undefined);
    const [createdBy, setCreatedBy] = useState<UserFragment | undefined>(undefined);
    const [total, setTotal] = useState(0);
    const actionTypeOptions = useMemo(() => enumToSelectOption(HistoryAction), []);
    const pagination = usePagination(total);
    const getHistoryEntries = useGetHistoryEntries({
        gameId: props.gameId,
        actionType: actionTypes,
        after: afterDate,
        before: beforeDate,
        createdBy: createdBy?.guid ?? null,
        offset: pagination.offset,
        take: pagination.take,
    });
    const { data } = getHistoryEntries;
    const onActionTypeChange = useCallback(
        (v: OnChangeValue<(typeof selectedActionTypes)[number], true>) => {
            setActionTypes(v.map((v) => v.value));
        },
        [],
    );

    useEffect(() => {
        if (data && data.historyEntriesCount !== total) setTotal(data.historyEntriesCount);
    }, [data]);

    return (
        <>
            <Row className="g-4 mb-4">
                <Col>
                    <Form.Group>
                        <Form.Label>After date</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            defaultValue={afterDate?.toISOString()}
                            onChange={(event) => setAfterDate(new Date(event.target.value))}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Before date</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            defaultValue={beforeDate?.toISOString()}
                            onChange={(event) => setBeforeDate(new Date(event.target.value))}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Author</Form.Label>
                        <UserSelect
                            gameId={gameId}
                            selectedUser={createdBy}
                            onSetSelectedUser={setCreatedBy}
                            isOptionDisabled={stubFalse}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Action types</Form.Label>
                        <Select
                            options={actionTypeOptions}
                            defaultValue={selectedActionTypes}
                            onChange={onActionTypeChange}
                            isClearable
                            isMulti
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-end">
                    <Pagination>
                        <Pagination.First onClick={pagination.onOpenFirst} />
                        <Pagination.Prev
                            disabled={!pagination.canOpenPrev}
                            onClick={pagination.onOpenPrev}
                        />
                        {pagination.currentPage > 1 && <Pagination.Ellipsis disabled />}
                        <Pagination.Item active disabled>
                            {pagination.currentPage}
                        </Pagination.Item>
                        {pagination.currentPage !== pagination.totalPages && (
                            <Pagination.Ellipsis disabled />
                        )}
                        <Pagination.Next
                            disabled={!pagination.canOpenNext}
                            onClick={pagination.onOpenNext}
                        />
                        <Pagination.Last onClick={pagination.onOpenLast} />
                    </Pagination>
                </Col>
            </Row>
            <div className="overflow-scroll">
                <DataAwaiter2 {...getHistoryEntries}>
                    {(data) =>
                        data.historyEntries.length > 0 ? (
                            <div className="g-2">
                                {data.historyEntries.map((entry) => (
                                    <Col key={entry.guid} md={12}>
                                        <HistoryEntry entry={entry} />
                                    </Col>
                                ))}
                            </div>
                        ) : (
                            <span>History has no entries matching current criteria</span>
                        )
                    }
                </DataAwaiter2>
            </div>
        </>
    );
}
