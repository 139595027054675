import { useMemo } from "react";
import flatten from "lodash/flatten";
import orderBy from "lodash/orderBy";
import { Category } from "../types";

export function useFilterCategories(filter: string, categories: Category[]) {
    const filterValue = useMemo(() => filter.toLowerCase().trim(), [filter]);

    const contains = useMemo<Category[]>(() => {
        return categories
            .map((c) => ({
                ...c,
                templates: c.templates.filter((t) => t.name.toLowerCase().includes(filterValue)),
            }))
            .filter((c) => c.templates.length > 0);
    }, [categories, filterValue]);

    const exact = useMemo<Category>(
        () => ({
            name: "Exact",
            gameId: "1111",
            guid: "1111",
            templates: orderBy(
                flatten(categories.map((c) => c.templates)).filter(
                    (t) => t.name.toLowerCase() === filterValue,
                ),
                "name",
            ),
        }),
        [categories, filterValue],
    );

    return useMemo(() => [contains, exact] as const, [contains, exact]);
}
