import Button from "react-bootstrap/Button";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { useCallback } from "react";

type Props = {
    spreadsheetId: string;
};

function ExportResultModalComponent(props: Props) {
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${props.spreadsheetId}/edit#gid=0`;

    return (
        <Modal {...dialog}>
            <Modal.Body>
                <h3>Data has been exported successfully</h3>
                <p>You can see the file by clicking the button below</p>
                <Button as="a" href={spreadsheetUrl} target="_blank">
                    Open spreadsheet
                </Button>
            </Modal.Body>
        </Modal>
    );
}

const ExportResultModal = create<Props>(ExportResultModalComponent);

export function useShowExportResultModal() {
    return useCallback((spreadsheetId: string) => {
        return show(ExportResultModal, { spreadsheetId });
    }, []);
}
