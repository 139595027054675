import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { FaFileExport } from "react-icons/fa";
import { showImportTranslationsModal } from "../../../modals/ImportTranslationsModal";
import { useTranslationsPageContext } from "../TranslationsPageProvider";

export function ImportButton() {
    const { translations, gameId } = useTranslationsPageContext();
    const onImport = useCallback(async () => {
        await showImportTranslationsModal({ gameId });
        await translations.refetch();
    }, [gameId, translations]);

    return (
        <Button onClick={onImport}>
            <FaFileExport /> Import from spreadsheet
        </Button>
    );
}
