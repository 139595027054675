import { Link } from "@tanstack/react-router";
import { IncorrectValueEvent } from "@/gql";
import { entityPageRoute } from "@/pages/EntityPage";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

interface IncorrectValueEventRendererProps {
    event: IncorrectValueEvent;
}

export function IncorrectValueEventRenderer(props: IncorrectValueEventRendererProps) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    return (
        <span>
            <Link
                to={entityPageRoute.to}
                params={{
                    gameId,
                    entityId: props.event.entityId,
                }}
                target="_blank"
            >
                {props.event.error}
            </Link>
        </span>
    );
}
