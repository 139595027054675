import { gql } from "@apollo/client";
import { TRANSLATION_FRAGMENT } from "@/graphql/fragments/translation.fragment";
import { makeGqlMutationHook } from "@/graphql/util";
import {
    UpdateTranslationFromDeeplMutation,
    UpdateTranslationFromDeeplMutationVariables,
} from "@/gql";

const SCHEMA = gql`
    ${TRANSLATION_FRAGMENT}

    mutation updateTranslationFromDeepl($guid: String!, $gameId: String!) {
        updateTranslationFromDeepl(guid: $guid, gameId: $gameId) {
            ...Translation
            translationStrings {
                guid
                value
                localeId
                isProtected
            }
        }
    }
`;

export const useUpdateTranslationFromDeeplMutation = makeGqlMutationHook<
    UpdateTranslationFromDeeplMutation,
    UpdateTranslationFromDeeplMutationVariables
>(SCHEMA);
