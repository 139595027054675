import styled from "styled-components";
import { ScrollSpy } from "@/components/common/ScrollSpy";

export const ScrollSpyLink = styled.p`
    padding: 0.5rem;
    border-left: 2px solid transparent;
    transition: color 0.2s ease-in-out;
    margin: 0;

    &.active-scroll-spy {
        border-left-color: var(--bs-primary);
    }
`;

export const ScrollSpyContainer = styled(ScrollSpy)`
    overflow: scroll;
`;

export const TemplateCategoriesWrapper = styled.div`
    overflow: hidden;
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: 9fr 3fr;
    grid-gap: 0.25rem;
`;

export const FilterInputCell = styled.div`
    grid-row: 1/2;
    grid-column: 1/3;
`;

export const ManageCategoriesCell = styled.div`
    grid-row: 2/3;
    grid-column: 1/3;
`;

export const CategoriesWrapperCell = styled.div`
    overflow-y: scroll;
    grid-row: 3/4;
    grid-column: 1/2;
`;

export const NavContainerCell = styled.div`
    grid-row: 3/4;
    grid-column: 2/3;
`;
