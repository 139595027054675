import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { FaTrash } from "react-icons/fa";
import { useDeleteTemplateCategory } from "@/graphql/templates";
import { TemplateCategory } from "@/types";
import { useTemplateCategoriesProvider } from "@/components/categories/providers/TemplateCategoriesProvider";
import { IconButton } from "../../common/IconButton";

type Props = {
    category: TemplateCategory;
};

export function DeleteCategoryButton(props: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const [deleteTemplateCategory] = useDeleteTemplateCategory({});
    const { deleteCategory, query } = useTemplateCategoriesProvider();
    const onDeleteCategory = useCallback(async () => {
        deleteCategory({ categoryId: props.category.guid });
        await deleteTemplateCategory({
            variables: { guid: props.category.guid, gameId: props.category.gameId },
            onCompleted: () => {
                enqueueSnackbar(`Category ${props.category.name} deleted`, {
                    variant: "success",
                });
                return query.refetch();
            },
        });
    }, [
        deleteCategory,
        props.category.guid,
        props.category.gameId,
        props.category.name,
        deleteTemplateCategory,
        enqueueSnackbar,
        query,
    ]);

    return (
        <IconButton width={1.5} icon={FaTrash} title="Delete category" onClick={onDeleteCategory} />
    );
}
