import {
    ForwardedRef,
    forwardRef,
    PropsWithChildren,
    ReactNode,
    useCallback,
    useMemo,
} from "react";
import Button from "react-bootstrap/Button";
import noop from "lodash/noop";
import cn from "classnames";
import { useShowConfirmationModal } from "../../modals/ConfirmationModal";
import type { ButtonProps } from "react-bootstrap/Button";

interface ConfirmationButtonProps {
    title?: string | ReactNode | ReactNode[];
    confirmButtonText?: string;
    prompt?: string;
    confirmationPhrase?: string;
    onConfirm: () => void;
    onCancel?: () => void;
}

export const ConfirmationButton = forwardRef(function ConfirmationButton(
    props: PropsWithChildren<ConfirmationButtonProps & ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>,
) {
    const {
        prompt = "Please confirm this action",
        title,
        className,
        disabled,
        confirmButtonText,
        confirmationPhrase,
        onConfirm,
        onCancel = noop,
        ...buttonProps
    } = props;
    const classNames = useMemo(() => cn(className, { disabled }), [className, disabled]);
    const showConfirmationModal = useShowConfirmationModal();
    const onClick = useCallback(async () => {
        if (disabled) return;
        const result = await showConfirmationModal({
            confirmationPhrase,
            prompt,
            title,
            confirmButtonText,
        });

        return result ? onConfirm() : onCancel();
    }, [
        confirmButtonText,
        confirmationPhrase,
        disabled,
        onCancel,
        onConfirm,
        prompt,
        showConfirmationModal,
        title,
    ]);
    return <Button {...buttonProps} className={classNames} ref={ref} onClick={onClick} />;
});
