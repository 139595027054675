import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import { TranslationByIdsQuery, TranslationByIdsQueryVariables } from "@/gql";

const SCHEMA = gql`
    query translationByIds($gameId: String!, $guid: [String!]!) {
        translationByIds(gameId: $gameId, guid: $guid) {
            guid
            gameId
            key
            description
            translationStrings {
                guid
                value
                localeId
                isProtected
            }
        }
    }
`;

export const useGetTranslationsByIds = makeGqlQueryLazyHook<
    TranslationByIdsQuery,
    TranslationByIdsQueryVariables
>(SCHEMA);
