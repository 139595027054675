import { useFormContext } from "react-hook-form";
import Stack from "react-bootstrap/Stack";
import cn from "classnames";
import { useEffect } from "react";
import { mapKeys } from "lodash";
import mapValues from "lodash/mapValues";
import stubTrue from "lodash/stubTrue";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useGetTemplate } from "@/graphql/templates";
import { ExportFormProps } from "./types";
import { SelectableTemplateParam } from "./SelectableTemplateParam";

type Props = {
    templateId: string;
    disabled?: boolean;
    shouldSelectAll?: boolean;
};

export function TemplateSelect(props: Props) {
    const { getValues, setValue } = useFormContext<ExportFormProps>();
    const gameId = getValues("gameId");
    const getTemplate = useGetTemplate({ guid: props.templateId, gameId });

    useEffect(() => {
        if (!getTemplate.data) return;

        const template = getTemplate.data.template;
        setValue(
            `templates.${template.guid}`,
            props.shouldSelectAll
                ? mapValues(
                      mapKeys(template.templateParams, (v) => v.guid),
                      stubTrue,
                  )
                : {},
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTemplate.data, setValue]);

    return (
        <DataAwaiter2 {...getTemplate}>
            {({ template }) => (
                <Stack className="p-2 border rounded" gap={2}>
                    <div className="fs-6">{template.name}</div>
                    <Stack className="overflow-y-scroll">
                        {template.templateParams!.map((tp, index) => (
                            <SelectableTemplateParam
                                key={tp.guid}
                                className={cn("align-items-center", {
                                    "bg-body-secondary": index % 2 === 0,
                                })}
                                templateParam={tp}
                                shouldSelectAll={props.shouldSelectAll}
                            />
                        ))}
                    </Stack>
                </Stack>
            )}
        </DataAwaiter2>
    );
}
