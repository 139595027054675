import { memo } from "react";
import {
    FaCog,
    FaFile,
    FaFileImport,
    FaGlobe,
    FaHistory,
    FaList,
    FaPaperPlane,
    FaPeopleArrows,
    FaPuzzlePiece,
    FaSearch,
} from "react-icons/fa";
import { Link } from "@tanstack/react-router";
import Nav from "react-bootstrap/Nav";
import cn from "classnames";
import { useAppSelector } from "@/hooks";
import { NavbarItem } from "@/components/common/Sidebar/NavbarItem";
import { Permission } from "@/gql";
import { UserDropdown } from "@/components/layout/UserDropdown";
import { MenuButton } from "@/components/layout/MenuButton";
import { RBACWrapper } from "@/components/rbac";
import { gameDetailsSelector } from "@/store/games/selectors";
import { NavbarButton } from "@/components/common/Sidebar/NavbarButton";
import { useSearchPromptModal } from "@/components/modals/SearchPromptModal";

type Props = {
    className?: string;
    menuState: boolean;
    onClick: () => void;
};

export const Sidebar = memo(function Sidebar(props: Props) {
    const { guid: gameId } = useAppSelector(gameDetailsSelector);
    const showFindEntityPrompt = useSearchPromptModal();

    return (
        <div
            className={cn(
                "d-flex flex-column align-items-center flex-shrink-0 shadow",
                props.className,
                {
                    "bg-body-tertiary": !window.env.isUnstable,
                    "bg-warning": window.env.isUnstable,
                },
            )}
        >
            <Link
                to="/games"
                className={cn(
                    "d-flex flex-column align-items-center text-decoration-none mt-3 mb-2",
                    {
                        "text-white": window.env.isUnstable,
                    },
                )}
            >
                <div className="fs-4 text-uppercase fw-bold">Eris</div>
                {window.env.isUnstable && <div className="small">unstable</div>}
            </Link>
            <RBACWrapper
                requiredPermissions={[
                    Permission.StructureRead,
                    Permission.StructureWrite,
                    Permission.DataWrite,
                    Permission.DataRead,
                ]}
                oneOf
            >
                <div className="pt-2 pb-4">
                    <MenuButton isOpen={props.menuState} onClick={props.onClick} />
                </div>
            </RBACWrapper>
            <Nav
                variant="pills"
                fill
                className={cn(
                    "d-flex flex-column flex-nowrap align-items-center mb-auto text-center overflow-y-scroll overflow-x-visible",
                    {
                        unstable: window.env.isUnstable,
                    },
                )}
                style={{ scrollbarWidth: "none" }}
            >
                {window.env.isDev && (
                    <NavbarItem name="Blockly Sandbox" href="blockly" permissions={[]}>
                        <FaPuzzlePiece size={24} />
                    </NavbarItem>
                )}
                <NavbarItem
                    name="Import"
                    href="import"
                    permissions={[Permission.StructureRead, Permission.StructureWrite]}
                    oneOf
                >
                    <FaFileImport size={24} />
                </NavbarItem>
                <NavbarItem
                    name="History"
                    href="history"
                    permissions={[Permission.DataRead, Permission.StructureRead]}
                    oneOf
                >
                    <FaHistory size={24} />
                </NavbarItem>
                <NavbarItem
                    name="Translation"
                    href="translation/table"
                    permissions={[Permission.StructureRead, Permission.StructureWrite]}
                    oneOf
                >
                    <FaGlobe size={24} />
                </NavbarItem>
                <NavbarItem name="Artifacts" href="artifacts" permissions={[Permission.CanDeploy]}>
                    <FaPaperPlane size={24} />
                </NavbarItem>
                {gameId && (
                    <NavbarItem
                        name="Project"
                        href="project"
                        permissions={[Permission.RolesChange]}
                    >
                        <FaCog size={24} />
                    </NavbarItem>
                )}
                <NavbarItem
                    name="Consumers"
                    href="consumers"
                    permissions={[Permission.StructureRead, Permission.StructureWrite]}
                    oneOf
                >
                    <FaPeopleArrows size={24} />
                </NavbarItem>
                <NavbarItem
                    name="Assets"
                    href="assets"
                    permissions={[Permission.StructureWrite, Permission.StructureRead]}
                >
                    <FaFile size={24} />
                </NavbarItem>
                <NavbarItem
                    name="Templates"
                    href="templates"
                    permissions={[
                        Permission.StructureRead,
                        Permission.StructureWrite,
                        Permission.DataWrite,
                        Permission.DataRead,
                    ]}
                    oneOf
                >
                    <FaList size={24} />
                </NavbarItem>
                <NavbarItem
                    name="Global parameters"
                    href="global-params"
                    permissions={[
                        Permission.StructureRead,
                        Permission.StructureWrite,
                        Permission.DataWrite,
                        Permission.DataRead,
                    ]}
                    oneOf
                >
                    <FaGlobe size={24} />
                </NavbarItem>
                <NavbarButton
                    name="Find entity"
                    permissions={[Permission.DataWrite, Permission.DataRead]}
                    oneOf
                    onClick={() => showFindEntityPrompt({ gameId })}
                >
                    <FaSearch size={24} />
                </NavbarButton>
            </Nav>
            <UserDropdown />
        </div>
    );
});
