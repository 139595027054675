import { CellContext } from "@tanstack/react-table";
import Badge from "react-bootstrap/Badge";
import { FaLock } from "react-icons/fa";
import keyBy from "lodash/keyBy";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import { useCallback } from "react";
import Spinner from "react-bootstrap/Spinner";
import { IconButton } from "@/components/common/IconButton";
import { useUpdateLocalizationStringsMutation } from "@/graphql/translation";
import { useTranslationsPageContext } from "@/components/translation/translations/TranslationsPageProvider";
import { Locale } from "@/types";
import { TranslationData, TranslationDataValue } from "../../types";
import { StyledTranslationCell } from "./components";

export function TranslationCell(
    props: CellContext<TranslationData, TranslationDataValue> & { locale: Locale },
) {
    const translation = props.getValue();
    const { guid: translationId } = props.row.original;
    const { translations, gameId } = useTranslationsPageContext();
    const [updateLocalizationString, { loading }] = useUpdateLocalizationStringsMutation({
        onCompleted: (data) => {
            const updatedTranslations = mapValues(
                groupBy(data.updateTranslationStrings, (v) => v.translationId),
                (a) => keyBy(a, (v) => v.localeId),
            );
            translations.updateQuery((prevState) => {
                return {
                    ...prevState,
                    translations: prevState.translations.map((translation) => {
                        if (!Reflect.has(updatedTranslations, translation.guid)) return translation;

                        return {
                            ...translation,
                            translationStrings: translation.translationStrings.map((ts) => {
                                if (
                                    Reflect.has(updatedTranslations[translation.guid]!, ts.localeId)
                                )
                                    return updatedTranslations[translation.guid]![ts.localeId]!;

                                return ts;
                            }),
                        };
                    }),
                };
            });
        },
    });
    const onChangeProtected = useCallback(async () => {
        await updateLocalizationString({
            variables: {
                gameId,
                locStrings: [
                    {
                        isProtected: !translation.isProtected,
                        translationId,
                        localeId: props.locale.guid,
                    },
                ],
            },
        });
    }, [gameId, props.locale.guid, translation, translationId, updateLocalizationString]);

    return (
        <StyledTranslationCell className="small">
            {translation ? (
                <>
                    {!props.locale.isBaseLocale &&
                        (loading ? (
                            <Spinner size={"sm"} />
                        ) : (
                            <IconButton
                                className="d-inline-block me-2"
                                icon={FaLock}
                                variant={translation.isProtected ? "primary" : "secondary"}
                                disabled={loading}
                                onClick={onChangeProtected}
                            />
                        ))}
                    <span>{translation.value}</span>
                </>
            ) : (
                <Badge bg="info">No translation</Badge>
            )}
        </StyledTranslationCell>
    );
}
