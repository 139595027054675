import { useMemo } from "react";
import noop from "lodash/noop";
import sortBy from "lodash/sortBy";
import Stack from "react-bootstrap/Stack";
import ListGroup from "react-bootstrap/ListGroup";
import { Template, TemplateCategory } from "@/types";
import { useCategoriesSidebarContext } from "./CategoriesSidebarContext";
import { SidebarCategoryTemplate } from "./SidebarCategoryTemplate";

type Props = {
    className?: string;
    data: TemplateCategory & { templates: Template[] };
    onItemSelected?: () => void;
};

export function SidebarTemplatesCategory(props: Props) {
    const { onItemSelected = noop } = props;
    const sidebarContext = useCategoriesSidebarContext();
    const templates = useMemo(
        () =>
            sortBy(props.data.templates, (template) => {
                return sidebarContext.getIsPinned(template.guid) ? -1 : 1;
            }),
        [props.data.templates, sidebarContext],
    );
    const id = useMemo(() => props.data.name.replace(/\W/, ""), [props.data.name]);
    return (
        <Stack as="section" id={id} className={props.className}>
            <div className="fs-6 lh-lg px-3 py-1 d-flex align-items-center gap-2">
                <span className="flex-grow-1">{props.data.name}</span>
            </div>
            <ListGroup>
                {templates.map((template) => (
                    <SidebarCategoryTemplate
                        key={template.guid}
                        template={template}
                        onItemSelected={onItemSelected}
                        isPinned={sidebarContext.getIsPinned(template.guid)}
                        onPin={() => sidebarContext.togglePinned(template.guid)}
                        isFavorite={sidebarContext.getIsFavorite(template.guid)}
                        onFavorite={() => sidebarContext.toggleFavorite(template.guid)}
                    />
                ))}
            </ListGroup>
        </Stack>
    );
}
