import { CellContext } from "@tanstack/react-table";
import { TranslationData } from "../../types";

export function TranslationDescriptionCell(props: CellContext<TranslationData, "description">) {
    const description = props.getValue();
    return (
        <span className="small" title={description}>
            {description}
        </span>
    );
}
