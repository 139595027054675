import { useCallback } from "react";
import { useUpdateTranslationFromDeeplMutation } from "@/graphql/translation";
import { Translation } from "../../types";

export function useUpdateTranslationFromDeepl(onRefetch: () => void) {
    const [updateTranslationFromDeepl] = useUpdateTranslationFromDeeplMutation();

    return useCallback(
        async (row: Translation) => {
            await updateTranslationFromDeepl({
                variables: {
                    guid: row.guid,
                    gameId: row.gameId,
                },
            });
            return onRefetch();
        },
        [onRefetch, updateTranslationFromDeepl],
    );
}
