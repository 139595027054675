import { useMemo } from "react";
import sortBy from "lodash/sortBy";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { SidebarTemplatesCategory } from "@/components/categories/components/SidebarTemplatesCategory";
import { Category } from "../types";
import { useCategoriesSidebarContext } from "./CategoriesSidebarContext";

type Props = {
    templateCategories: Category[];
    onItemSelected: () => void;
};

export function FavoriteTemplatesCategory(props: Props) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const { getIsFavorite } = useCategoriesSidebarContext();
    const templateCategory = useMemo<Category>(
        () => ({
            gameId,
            name: "Favorites",
            guid: "",
            templates: sortBy(
                props.templateCategories
                    .map((tc) => tc.templates.filter((t) => getIsFavorite(t.guid)))
                    .flat(),
                "name",
            ),
        }),
        [gameId, props.templateCategories, getIsFavorite],
    );

    if (templateCategory.templates.length === 0) return null;

    return (
        <SidebarTemplatesCategory
            className="mb-4"
            key="favorites"
            data={templateCategory}
            onItemSelected={props.onItemSelected}
        />
    );
}
