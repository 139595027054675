import { useModal } from "@ebay/nice-modal-react";
import { useCallback } from "react";

export function useCancelModal() {
    const modal = useModal();

    return useCallback(() => {
        modal.reject();
        return modal.hide();
    }, [modal]);
}
