import { MouseEventHandler, useMemo } from "react";
import { TemplateCategory } from "@/types";
import { ScrollSpyLink } from "../TemplateCategoriesComponents";
import { scrollSpyDataAttributeName } from "../constants";

type Props = {
    onScrollToCategory: MouseEventHandler<HTMLAnchorElement>;
    category: TemplateCategory;
};

export function TemplateCategoriesNavItem(props: Props) {
    const { onScrollToCategory, category } = props;
    const scrollspyId = useMemo(() => category.name.replace(/\W/, ""), [category.name]);
    const dataAttribute = useMemo(
        () => ({ [`data-${scrollSpyDataAttributeName}`]: scrollspyId }),
        [scrollspyId],
    );

    return (
        <a href={`#${scrollspyId}`} onClick={onScrollToCategory} {...dataAttribute}>
            <ScrollSpyLink data-to-scrollspy-id={scrollspyId}>{category.name}</ScrollSpyLink>
        </a>
    );
}
