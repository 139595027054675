import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import { useFormContext } from "react-hook-form";
import cn from "classnames";
import { useCallback, useMemo } from "react";
import { FaUndo } from "react-icons/fa";
import { IconButton } from "@/components/common/IconButton";
import { UpdateTranslationFormData } from "../../types";
import { useTranslationFieldContext } from "../contexts/TranslationFieldContext";
import { ChangeProtectedFlagField } from "./ChangeProtectedFlagField";
import { FillTranslationFromDeeplButton } from "./FillTranslationFromDeeplButton";

export function UpdateTranslationField() {
    const { locale } = useTranslationFieldContext();
    const form = useFormContext<UpdateTranslationFormData>();
    const isFieldDirty = form.formState.dirtyFields.values?.[locale.guid];
    const formControlClassName = useMemo(
        () =>
            cn({
                "border-warning-subtle": isFieldDirty,
            }),
        [isFieldDirty],
    );
    const onResetField = useCallback(() => {
        form.resetField(`values.${locale.guid}`);
        // form.resetField(`values.${locale.guid}.isProtected` as const);
    }, [form, locale.guid]);

    return (
        <Form.Group>
            <div className="d-flex align-items-center gap-2 mb-2">
                {!locale.isBaseLocale && (
                    <>
                        <ChangeProtectedFlagField />
                        <FillTranslationFromDeeplButton />
                    </>
                )}
                <Form.Label className="d-flex align-items-center gap-1 ms-0">
                    <span>
                        {locale.name} ({locale.code})
                    </span>
                    {locale.isBaseLocale && <Badge>Base locale</Badge>}
                </Form.Label>
                {isFieldDirty && <IconButton icon={FaUndo} onClick={onResetField} />}
            </div>
            <Form.Control
                {...form.register(`values.${locale.guid}.value`)}
                as="textarea"
                rows={10}
                className={formControlClassName}
            />
        </Form.Group>
    );
}
