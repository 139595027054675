import { useLocalStorage } from "usehooks-ts";
import { useCallback, useMemo } from "react";

export function useLocalStorageSet(key: string) {
    const [collection, setCollection] = useLocalStorage<string[]>(key, []);
    const collectionSet = useMemo(() => new Set(collection), [collection]);
    const isPresent = useCallback((guid: string) => collectionSet.has(guid), [collectionSet]);
    const onToggleItem = useCallback(
        (guid: string) => {
            const newCollection = new Set(collectionSet);
            if (newCollection.has(guid)) {
                newCollection.delete(guid);
            } else {
                newCollection.add(guid);
            }
            setCollection([...newCollection]);
        },
        [collectionSet, setCollection],
    );

    return useMemo(
        () => ({ collection, collectionSet, isPresent, onToggleItem }),
        [collection, collectionSet, isPresent, onToggleItem],
    );
}
