import { createContext, useContext } from "react";

type UpdateTranslationModalContext = {
    loading: boolean;
    baseLocaleValue: string;
};

export const UpdateTranslationModalContext = createContext<UpdateTranslationModalContext>(
    undefined!,
);

export function useUpdateTranslationModalContext() {
    return useContext(UpdateTranslationModalContext);
}
