import { gql } from "@apollo/client";
import { makeGqlQueryLazyHook } from "@/graphql/util";
import { GetTranslationsFromDeeplQuery, GetTranslationsFromDeeplQueryVariables } from "@/gql";

const SCHEMA = gql`
    query getTranslationsFromDeepl($gameId: String!, $value: String!) {
        getTranslationsFromDeepl(gameId: $gameId, value: $value) {
            value
            localeId
        }
    }
`;

export const useGetTranslationsFromDeepl = makeGqlQueryLazyHook<
    GetTranslationsFromDeeplQuery,
    GetTranslationsFromDeeplQueryVariables
>(SCHEMA);
