import { useMemo } from "react";
import { Link } from "@tanstack/react-router";
import { UniqCheckFailedEvent } from "@/gql";
import { stringify } from "@/utils/stringify";
import { entityPageRoute } from "@/pages/EntityPage";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

interface UniqCheckFailedEventProps {
    event: UniqCheckFailedEvent;
}

export function UniqCheckFailedEventRenderer(props: UniqCheckFailedEventProps) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const value = useMemo(
        () => stringify(props.event.entityParameterValue),
        [props.event.entityParameterValue],
    );

    return (
        <span>
            <Link
                to={entityPageRoute.to}
                params={{ gameId, entityId: props.event.entityId }}
                target="_blank"
            >
                {props.event.error}
            </Link>
            . Non-unique value is <span className="font-monospace">{value}</span>
        </span>
    );
}
