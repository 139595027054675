import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useLocalStorageSet } from "@/hooks/useLocalStorageSet";

type CategoriesSidebarContext = {
    favoritesSet: Set<string>;
    getIsFavorite: (guid: string) => boolean;
    toggleFavorite: (guid: string) => void;
    pinnedSet: Set<string>;
    getIsPinned: (guid: string) => boolean;
    togglePinned: (guid: string) => void;
};

const favoriteTemplatesKey = (gameId: string) => `FAVORITE_TEMPLATES_KEY-${gameId}`;
const pinnedTemplatesKey = (gameId: string) => `PINNED_TEMPLATES_KEY-${gameId}`;

const CategoriesSidebarContext = createContext<CategoriesSidebarContext>(undefined!);

export function CategoriesSidebarContextProvider(props: PropsWithChildren<{ gameId: string }>) {
    const { gameId, children } = props;
    const {
        collection: favoritesCollection,
        collectionSet: favoritesSet,
        isPresent: isFavorite,
        onToggleItem: toggleFavorite,
    } = useLocalStorageSet(favoriteTemplatesKey(gameId));
    const {
        collection: pinnedCollection,
        collectionSet: pinnedSet,
        isPresent: isPinned,
        onToggleItem: togglePinned,
    } = useLocalStorageSet(pinnedTemplatesKey(gameId));

    const value: CategoriesSidebarContext = useMemo(
        () => ({
            favoritesCollection,
            favoritesSet,
            getIsFavorite: isFavorite,
            toggleFavorite,
            pinnedCollection,
            pinnedSet,
            getIsPinned: isPinned,
            togglePinned,
        }),
        [
            favoritesCollection,
            favoritesSet,
            isFavorite,
            isPinned,
            pinnedCollection,
            pinnedSet,
            toggleFavorite,
            togglePinned,
        ],
    );

    return (
        <CategoriesSidebarContext.Provider value={value}>
            {children}
        </CategoriesSidebarContext.Provider>
    );
}

export function useCategoriesSidebarContext() {
    return useContext(CategoriesSidebarContext);
}
