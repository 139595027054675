import { useCallback } from "react";
import cn from "classnames";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import { ArtifactDeploymentEvent, DeployGameEventsQuery, DeployStep } from "@/gql";
import { DateDisplay } from "@/components/common/DateDisplay";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { MessageRenderer } from "../MessageRenderer";

interface DeployResultsProps {
    className?: string;
    results: DeployGameEventsQuery["events"][number][];
}

const separatedMessages = [
    DeployStep.ConsumerProcessing,
    DeployStep.ConsumerProcessingFinished,
    DeployStep.DeployStarted,
    DeployStep.DeployFinished,
];

export function DeployResults(props: DeployResultsProps) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const classNames = useCallback(
        (message: Omit<ArtifactDeploymentEvent, "__typename">) =>
            cn({
                "py-2": separatedMessages.includes(message.deployCode),
                "my-2": separatedMessages.includes(message.deployCode),
                "border-top": message.deployCode === DeployStep.ConsumerProcessingFinished,
                "border-bottom": message.deployCode === DeployStep.ConsumerProcessing,
            }),
        [],
    );

    return (
        <Stack className={cn("flex-column-reverse", props.className)}>
            {props.results.map((message, index) => {
                const artifactId = message.artifactId?.split("-").at(0);

                return (
                    <div key={index} className={classNames(message)}>
                        <span className="text-bg-primary font-monospace p-1 rounded rounded-2 small">
                            <DateDisplay date={message.createdAt} format="HH:mm:ss:SSS" />
                        </span>
                        &nbsp;
                        <span>
                            <Badge>{artifactId}</Badge>
                        </span>
                        &nbsp;
                        <Badge
                            bg={message.error ? "danger" : "primary"}
                            className="text-capitalize"
                        >
                            {message.deployCode}
                        </Badge>
                        &nbsp;
                        <MessageRenderer event={message} gameId={gameId!} />
                    </div>
                );
            })}
        </Stack>
    );
}
