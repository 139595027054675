import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useSnackbar } from "notistack";
import { MdTranslate } from "react-icons/md";
import { useGetTranslationFromDeepl } from "@/graphql/translation";
import { IconButton } from "../../../common/IconButton";
import { useTranslationFieldContext } from "../contexts/TranslationFieldContext";
import { useUpdateTranslationModalContext } from "../contexts/UpdateTranslationModalContext";
import { UpdateTranslationFormData } from "../../types";

export function FillTranslationFromDeeplButton() {
    const { locale } = useTranslationFieldContext();
    const { loading, baseLocaleValue } = useUpdateTranslationModalContext();
    const form = useFormContext<UpdateTranslationFormData>();
    const { enqueueSnackbar } = useSnackbar();
    const [getTranslationFromDeepl] = useGetTranslationFromDeepl();
    const onFillTranslationFromDeepl = useCallback(async () => {
        const { gameId, guid } = locale;

        if (!baseLocaleValue) {
            enqueueSnackbar("Base locale value is empty or does not exist", {
                variant: "error",
                autoHideDuration: 3000,
            });
            return;
        }

        const result = await getTranslationFromDeepl({
            variables: { gameId, sourceLocaleId: guid, value: baseLocaleValue },
        });

        if (!result.data?.getTranslationFromDeepl) {
            enqueueSnackbar("Could not get translation", {
                variant: "error",
                autoHideDuration: 3000,
            });
            return;
        }

        form.setValue(`values.${locale.guid}.value`, result.data.getTranslationFromDeepl.value);
    }, [baseLocaleValue, enqueueSnackbar, form, getTranslationFromDeepl, locale]);

    return (
        <IconButton
            size="sm"
            variant="secondary"
            disabled={baseLocaleValue.length === 0 || loading}
            onClick={onFillTranslationFromDeepl}
            icon={MdTranslate}
        />
    );
}
