import Button from "react-bootstrap/Button";
import { FaFileImport } from "react-icons/fa";
import { useCallback } from "react";
import { showExportTranslationModal } from "../../../modals/ExportTranslationsModal";
import { useTranslationsPageContext } from "../TranslationsPageProvider";

export function ExportButton() {
    const { translations, gameId } = useTranslationsPageContext();
    const onExport = useCallback(async () => {
        return showExportTranslationModal({
            gameId,
            locales: (await translations.observable.result()).data.locales,
        });
    }, [gameId, translations.observable]);

    return (
        <Button onClick={onExport}>
            <FaFileImport /> Export to spreadsheet
        </Button>
    );
}
