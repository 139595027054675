import { Link } from "@tanstack/react-router";
import { MissingReferencedEntityEvent } from "@/gql";
import { entityPageRoute } from "@/pages/EntityPage";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

type Props = { event: MissingReferencedEntityEvent };

export function MissingReferencedEntityEventRenderer(props: Props) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const { entityParameterName, referencingEntityId } = props.event;

    return (
        <span>
            Parameter ${entityParameterName} (
            <Link
                to={entityPageRoute.to}
                from="/"
                params={{
                    gameId,
                    entityId: referencingEntityId,
                }}
                target="_blank"
            >
                {entityParameterName}
            </Link>
            ) is missing referenced entity
        </span>
    );
}
