import {
    CSSProperties,
    forwardRef,
    MouseEventHandler,
    ReactNode,
    useCallback,
    useMemo,
} from "react";
import { Link } from "@tanstack/react-router";
import cn from "classnames";
import { FaExternalLinkAlt } from "react-icons/fa";
import ListGroup from "react-bootstrap/ListGroup";
import styled from "styled-components";
import { IconButton } from "@/components/common/IconButton";
import { TextEllipsis } from "@/components/common/TextEllipsis";
import { Template } from "@/types";

type Props = {
    template: Template;
    onItemSelected?: () => void;
    className?: string;
    style?: CSSProperties;
    start?: ReactNode | ReactNode[];
    end?: ReactNode | ReactNode[];
};

export const CategoryTemplate = forwardRef<HTMLDivElement, Props>(
    function CategoryTemplate(props, ref) {
        const uri = useMemo(
            () => `/${props.template.gameId}/templates/${props.template.guid}/entities`,
            [props.template.gameId, props.template.guid],
        );
        const isActive = location.pathname === uri;

        const className = useMemo(
            () =>
                cn(
                    "d-flex align-items-center justify-content-start text-reset gap-2 px-3 py-1 lh-lg",
                    props.className,
                    {
                        "bg-primary": isActive,
                        "text-white": isActive,
                    },
                ),
            [isActive, props.className],
        );
        const onItemSelected = useCallback<MouseEventHandler<HTMLAnchorElement>>(
            (e) => {
                e.stopPropagation();
                e.preventDefault();
                typeof props.onItemSelected === "function" && props.onItemSelected();
            },
            [props],
        );

        return (
            <ListGroup.Item
                as="div"
                action
                className={className}
                ref={ref}
                style={props.style}
                onClick={onItemSelected}
            >
                {props.start}
                <Link to={uri}>
                    {() => <IconButton variant="link" icon={FaExternalLinkAlt} width={1.5} />}
                </Link>
                <ShitGrid className="flex-grow-1">
                    <TextEllipsis className="ms-1 fs-6" title={props.template.name}>
                        {props.template.name}
                    </TextEllipsis>
                    <div className="d-flex">
                        <TextEllipsis
                            className="small"
                            title={props.template?.description ?? "No description"}
                        >
                            {props.template?.description ?? "&nbsp;"}
                        </TextEllipsis>
                    </div>
                </ShitGrid>
                {props.end}
            </ListGroup.Item>
        );
    },
);

const ShitGrid = styled.div`
    display: grid;
    grid-template-columns: minmax(20rem, 1fr) minmax(30rem, 2fr);
`;
