import { useController, useFormContext } from "react-hook-form";
import { useMemo } from "react";
import Row from "react-bootstrap/Row";
import cn from "classnames";
import Col from "react-bootstrap/Col";
import ToggleButton from "react-bootstrap/ToggleButton";
import { useTemplate2 } from "@/store/games/slices";
import { TemplateParam, TemplateType } from "@/gql";
import { ExportFormProps } from "./types";
import { TemplateSelect } from "./TemplateSelect";

type Props = {
    templateParam: TemplateParam;
    className?: string;
    disabled?: boolean;
    shouldSelectAll?: boolean;
};

export function SelectableRefParam(props: Props) {
    const { control } = useFormContext<ExportFormProps>();
    const tp = props.templateParam;
    const [refTemplate] = useTemplate2(tp.meta.templateId!);
    const refdTemplateHasNoTitle = useMemo(() => {
        if (!refTemplate || !refTemplate.templateParams) return false;
        return !refTemplate.templateParams.find((tp) => tp.name === "_Title");
    }, [refTemplate]);
    const cannotSelectHasNoTitle =
        refdTemplateHasNoTitle && refTemplate?.type === TemplateType.Document;
    const fieldName = `templates.${tp.templateId!}.${tp.guid}` as const;
    const controller = useController<ExportFormProps, `templates.${string}.${string}`>({
        name: fieldName,
        control,
        defaultValue: Boolean(props.shouldSelectAll),
        // defaultValue: false,
        shouldUnregister: true,
        disabled: props.disabled || cannotSelectHasNoTitle,
    });

    return (
        <Row key={tp.guid} className={cn(props.className, "d-flex")}>
            <Col md={2}>
                <ToggleButton
                    type="checkbox"
                    size="sm"
                    id={fieldName}
                    value={fieldName}
                    disabled={controller.field.disabled}
                    name={controller.field.name}
                    checked={controller.field.value}
                    onChange={controller.field.onChange}
                    onBlur={controller.field.onBlur}
                    ref={controller.field.ref}
                >
                    {cannotSelectHasNoTitle
                        ? "Has no title"
                        : controller.field.value
                          ? "Selected"
                          : "Select"}
                </ToggleButton>
            </Col>
            <Col md={3}>{tp.name}</Col>
            <Col md={3}>{refTemplate ? `${tp.type}<${refTemplate.name}>` : tp.type}</Col>
            <Col className="small" md={4}>
                {tp.guid}
            </Col>
            {controller.field.value && refTemplate?.type !== TemplateType.Document && (
                <Col md={12}>
                    <section>
                        <TemplateSelect
                            templateId={tp.meta.templateId!}
                            shouldSelectAll={props.shouldSelectAll}
                        />
                    </section>
                </Col>
            )}
        </Row>
    );
}
