import Spinner from "react-bootstrap/Spinner";
import { DataType } from "@/gql";
import { useTemplate2 } from "@/store/games/slices";
import { AllowedListSubType } from "@/types";
import { EntityRefField } from "../EntityRef/EntityRefTableCell";
import { InjectedRefField } from "../InjectedRef/InjectedRefTableCell";
import { ListEditorProps } from "./types";

export function ListEditorRefField(
    props: ListEditorProps & { value: any; readonly: boolean; onChange: (value: any) => void },
) {
    const { value, readonly, onChange } = props;
    const commonProps = { value, readonly, onChange };
    const templateId = props.templateParam.meta.templateId;
    if (!templateId) {
        throw new Error(`Parameter ${props.templateParam.name} doesn't reference a template`);
    }
    const [referencedTemplate, loading] = useTemplate2(templateId);
    const subType = props.templateParam.subType as AllowedListSubType;
    switch (subType) {
        case DataType.EntityRef:
            if (loading) return <Spinner size="sm" />;

            if (!referencedTemplate) {
                throw new Error("EntityRef: Referenced template is missing");
            }
            if (!props.templateParam.meta.templateId) {
                throw new Error("EntityRef: No template to reference");
            }
            return (
                <EntityRefField
                    {...commonProps}
                    gameId={props.gameId}
                    templateParam={props.templateParam}
                    templateId={props.templateParam.meta.templateId}
                    contextEntity={props.contextEntity}
                />
            );
        case DataType.InjectedRef:
            if (loading) return <Spinner size="sm" />;

            if (!referencedTemplate) {
                throw new Error("InjectedRef: Referenced template is missing");
            }
            if (!props.templateParam.meta.templateId) {
                throw new Error("InjectedRef: No template to reference");
            }
            return (
                <InjectedRefField
                    {...commonProps}
                    gameId={props.gameId}
                    templateParam={props.templateParam}
                    templateId={props.templateParam.meta.templateId}
                    contextEntity={props.contextEntity}
                />
            );
    }
}
