import { useCallback, useEffect, useMemo } from "react";
import { DeployStep } from "@/gql";
import { useDeployGameQuery, useGenerateArtifactMutation } from "@/graphql/deploy";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

type Listener = () => void;

const listeners = new Set<Listener>();
const startEvents = new Set<DeployStep>([
    DeployStep.ArtifactGenerationStarted,
    DeployStep.DeployStarted,
]);
const failEvents = new Set<DeployStep>([
    DeployStep.ArtifactGenerationFailed,
    DeployStep.DeployFailed,
]);
const finishEvents = new Set<DeployStep>([
    DeployStep.ArtifactGenerationFinished,
    DeployStep.ArtifactGenerationFailed,
    DeployStep.DeployFinished,
    DeployStep.DeployFailed,
]);

export function useDeploy() {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const deployGameQuery = useDeployGameQuery({ gameId });
    const [generateArtifactMutation] = useGenerateArtifactMutation();
    const startBuild = useCallback(async () => {
        if (!gameId) return;

        await generateArtifactMutation({ variables: { gameId } });
        await deployGameQuery.refetch();
    }, [deployGameQuery, gameId, generateArtifactMutation]);
    const deployMessages = useMemo(
        () => deployGameQuery.data?.events ?? [],
        [deployGameQuery.data?.events],
    );
    const indexTester = useCallback(
        (collection: Set<DeployStep>) => {
            return deployMessages.findLastIndex((message) => collection.has(message.deployCode));
        },
        [deployMessages],
    );
    const deployInProgress = useMemo(
        () => Boolean(deployGameQuery.data?.deployInProgress),
        [deployGameQuery.data?.deployInProgress],
    );
    const hasErrors = useMemo(
        () => !deployMessages.every((message) => !message.error),
        [deployMessages],
    );
    const lastDeployStartedEventIndex = useMemo(() => indexTester(startEvents), [indexTester]);
    const lastDeployFailedEventIndex = useMemo(() => indexTester(failEvents), [indexTester]);
    const lastDeployFinishedEventIndex = useMemo(() => indexTester(finishEvents), [indexTester]);
    const deployStarted = useMemo(
        () =>
            lastDeployStartedEventIndex > lastDeployFinishedEventIndex ||
            lastDeployStartedEventIndex > lastDeployFailedEventIndex,
        [lastDeployFailedEventIndex, lastDeployFinishedEventIndex, lastDeployStartedEventIndex],
    );
    const deployFailed = useMemo(
        () => lastDeployFailedEventIndex > lastDeployStartedEventIndex,
        [lastDeployFailedEventIndex, lastDeployStartedEventIndex],
    );
    const deployFinished = useMemo(
        () => lastDeployFinishedEventIndex > lastDeployStartedEventIndex,
        [lastDeployFinishedEventIndex, lastDeployStartedEventIndex],
    );
    const onComplete = useCallback((listener: Listener) => {
        listeners.add(listener);
        return () => {
            listeners.delete(listener);
        };
    }, []);

    useEffect(() => {
        const lastMessage = deployMessages.at(-1);

        if (lastMessage && finishEvents.has(lastMessage.deployCode)) {
            listeners.forEach((l) => l());
        }
    }, [deployMessages]);

    return useMemo(
        () => ({
            startBuild,
            deployInProgress,
            messages: deployMessages,
            deployStarted,
            deployFailed,
            deployFinished,
            hasErrors,
            onComplete,
        }),
        [
            deployFailed,
            deployFinished,
            deployMessages,
            deployStarted,
            deployInProgress,
            hasErrors,
            onComplete,
            startBuild,
        ],
    );
}
