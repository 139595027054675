import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import { gql, useQuery } from "@apollo/client";
import { useHasPermissions } from "@/components/rbac";
import {
    Consumer,
    EntitiesSortOrder,
    GetEditTemplateDataQuery,
    GetEditTemplateDataQueryVariables,
    Permission,
    Template,
    TemplateMeta,
    TemplateType,
} from "@/gql";
// import { TemplateMeta } from "@/types";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { TEMPLATE_FRAGMENT } from "@/graphql/fragments/template.fragment";
import { CONSUMER_FRAGMENT } from "@/graphql/fragments/consumer.fragment";
import { useTemplateCategories } from "@/graphql/templates";
import { TEMPLATE_CATEGORY_FRAGMENT } from "@/graphql/fragments/template-category.fragment";
import { FormInput } from "../common/forms/form-inputs/FormInput";
import { FormReactSelect } from "../common/forms/form-inputs/FormReactSelect";

export type EditTemplateFormData = {
    name: string;
    description: string;
    gameId: string;
    meta: TemplateMeta;
    category: { value: string; label: string } | null;
    type: { value: TemplateType; label: TemplateType };
    consumers: Omit<Consumer, "templates" | "templateParams" | "game">[];
};

interface CreateTemplateFormProps {
    templateId: string;
    gameId: string;
    isLoading: boolean;
    error: Error | null;
    onSubmit: (data: EditTemplateFormData) => Promise<Template> | Template;
    onDelete: () => void;
}

const getEditTemplateQuery = gql`
    ${TEMPLATE_FRAGMENT}
    ${CONSUMER_FRAGMENT}
    ${TEMPLATE_CATEGORY_FRAGMENT}

    query getEditTemplateData($guid: String!, $gameId: String!) {
        template(guid: $guid, gameId: $gameId) {
            ...Template
            consumers {
                ...Consumer
            }
            category {
                ...TemplateCategory
            }
        }
        getConsumers(gameId: $gameId) {
            ...Consumer
        }
    }
`;

export function EditTemplateForm(props: CreateTemplateFormProps) {
    const query = useQuery<GetEditTemplateDataQuery, GetEditTemplateDataQueryVariables>(
        getEditTemplateQuery,
        {
            variables: {
                guid: props.templateId,
                gameId: props.gameId,
            },
        },
    );
    const { template } = query.data ?? {};
    const templateCategories = useTemplateCategories({ variables: { gameId: props.gameId } });
    const availableTemplateCategories = useMemo(() => {
        if (!templateCategories.data) return [];

        return templateCategories.data.templateCategories.map((category) => ({
            value: category.guid,
            label: category.name,
        }));
    }, [templateCategories.data]);
    const defaultCategory = useMemo(() => {
        return templateCategories.data?.templateCategories.find(
            (category) => category.name === "Unassigned",
        );
    }, [templateCategories.data?.templateCategories]);
    const canEdit = useHasPermissions([Permission.StructureWrite]);
    const values: EditTemplateFormData = template
        ? {
              name: template.name,
              description: template.description,
              gameId: template.gameId,
              meta: template.meta,
              consumers: template.consumers!,
              category: template
                  ? { value: template.category!.guid, label: template.category!.name }
                  : null,
              type: template
                  ? { value: template.type, label: template.type }
                  : {
                        value: TemplateType.Document,
                        label: TemplateType.Document,
                    },
          }
        : {
              name: "",
              description: "",
              gameId: "",
              meta: {
                  listFieldsVerticallyInEditEntityModal: false,
                  defaultSortOrder: EntitiesSortOrder.Asc,
                  entitiesPerPage: 25,
              },
              consumers: [],
              category: null,
              type: {
                  value: TemplateType.Document,
                  label: TemplateType.Document,
              },
          };

    const { handleSubmit, control, formState, reset } = useForm<EditTemplateFormData>({
        defaultValues: values,
        values,
        mode: "onChange",
    });
    const onSubmit = useCallback(
        async (data: EditTemplateFormData) => {
            const result = await props.onSubmit(data);
            reset({
                gameId: result.gameId,
                name: result.name,
                description: result.description,
                meta: result.meta,
                type: { value: result.type, label: result.type },
                category: result.category
                    ? { value: result.category.guid, label: result.category.name }
                    : null,
                consumers: result.consumers ?? [],
            });
        },
        [props, reset],
    );
    const templateTypes = useMemo(() => {
        return Object.values(TemplateType).map((value) => ({
            value,
            label: value,
        }));
    }, []);

    return (
        <DataAwaiter2 {...query}>
            {(data) => (
                // <Form onSubmit={handleSubmit(props.onSubmit)}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col sm={12} md={12} lg={12} xl={8} xxl={5}>
                            <Row>
                                <Col>
                                    <FormInput
                                        className="mb-2"
                                        name="name"
                                        control={control}
                                        disabled={!canEdit}
                                        title="Display name"
                                        rules={{ required: true, min: 3, max: 250 }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormInput
                                        className="mb-2"
                                        name="description"
                                        as="textarea"
                                        control={control}
                                        disabled={!canEdit}
                                        title="Description"
                                        rules={{ required: false, max: 1024 }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <FormReactSelect
                                        className="mb-2"
                                        name="consumers"
                                        getOptionLabel={(consumer) => consumer.name}
                                        getOptionValue={(consumer) => consumer.guid}
                                        control={control}
                                        title="Consumers"
                                        isDisabled={!canEdit}
                                        options={data.getConsumers}
                                        isMulti
                                        isClearable
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormReactSelect
                                        className="mb-2"
                                        name="type"
                                        control={control}
                                        title="Template type"
                                        isDisabled={!canEdit}
                                        options={templateTypes}
                                        rules={{ required: true }}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <FormReactSelect
                                        className="mb-2"
                                        name="category"
                                        control={control}
                                        title="Category"
                                        isDisabled={!canEdit}
                                        options={availableTemplateCategories}
                                        rules={{ required: true }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {canEdit && (
                        <Row className="mt-3">
                            <Col sm={12} md={12} lg={12} xl={8} xxl={5}>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        type="submit"
                                        color="danger"
                                        disabled={
                                            props.isLoading ||
                                            !formState.isValid ||
                                            !formState.isDirty
                                        }
                                    >
                                        {props.isLoading ? (
                                            <Spinner size="sm" animation="border" />
                                        ) : (
                                            "Save template"
                                        )}
                                    </Button>
                                    <ConfirmationButton
                                        prompt="Are you sure you want to delete this template?"
                                        onConfirm={props.onDelete}
                                        variant="danger"
                                        confirmationPhrase={props.templateId}
                                    >
                                        Delete template
                                    </ConfirmationButton>
                                </div>
                            </Col>
                        </Row>
                    )}

                    {props.error ? (
                        <Row className="mt-3">
                            <Col>
                                <Alert variant="danger">
                                    <Alert.Heading>Failed to create template</Alert.Heading>
                                    <p>{props.error.message}</p>
                                </Alert>
                            </Col>
                        </Row>
                    ) : null}
                </Form>
            )}
        </DataAwaiter2>
    );
}
