import { useCallback, useMemo } from "react";
import { useForm, type SubmitHandler, FormProvider } from "react-hook-form";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useSnackbar } from "notistack";
import keyBy from "lodash/keyBy";
import Button from "react-bootstrap/Button";
import { Translation, TranslationString, UpdateTranslationMutationVariables } from "@/gql";
import { useGetTranslationFromDeepl, useUpdateTranslationMutation } from "@/graphql/translation";
import { Locale } from "@/types";
import { useProtectDirtyForm } from "@/hooks/useProtectDirtyForm";
import { UpdateTranslationFormData } from "@/components/translation/types";
import { ProgressButton } from "@/components/common/ProgressButton";
import { useCancelModal } from "@/components/translation/translations/hooks/useCancelModal";
import { FormInput2 } from "@/components/common/forms/form-inputs/FormInput2";
import { UpdateWithDeeplButton } from "@/components/translation/translations/components/UpdateWithDeeplButton";
import { UpdateTranslationModalContext } from "../contexts/UpdateTranslationModalContext";
import { TranslationFieldContext } from "../contexts/TranslationFieldContext";
import { UpdateTranslationField } from "../components/UpdateTranslationField";

type Props = {
    gameId: string;
    baseLocaleId: string;
    translation: Omit<Translation, "referencingParams" | "translationStrings"> & {
        translationStrings: Omit<TranslationString, "locale" | "translationId" | "gameId">[];
    };
    locales: Locale[];
};

function UpdateTranslationModalComponent(props: Props) {
    const { translation, baseLocaleId } = props;
    const modal = useModal();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = bootstrapDialog(modal);
    const [updateTranslationMutation, { loading: updateTranslationLoading }] =
        useUpdateTranslationMutation();
    const [, { loading: getTranslationFromDeeplLoading }] = useGetTranslationFromDeepl();
    const loading = updateTranslationLoading || getTranslationFromDeeplLoading;
    const defaultValues = useMemo<UpdateTranslationFormData>(
        () => ({
            gameId: translation.gameId,
            guid: translation.guid,
            key: translation.key,
            description: translation.description,
            values: keyBy(translation.translationStrings, "localeId"),
        }),
        [
            translation.description,
            translation.gameId,
            translation.guid,
            translation.key,
            translation.translationStrings,
        ],
    );
    const form = useForm<UpdateTranslationFormData>({
        mode: "onChange",
        defaultValues,
    });
    const onCancel = useCancelModal();
    const modalProtectionProps = useProtectDirtyForm(form);
    const baseLocaleValue = baseLocaleId ? form.watch(`values.${baseLocaleId}.value`) : "";
    const onSubmit = useCallback<SubmitHandler<UpdateTranslationFormData>>(
        async (data) => {
            const baseTranslationChanged = Boolean(
                form.formState.dirtyFields.values?.[baseLocaleId]?.value,
            );
            const variables: UpdateTranslationMutationVariables = {
                gameId: data.gameId,
                guid: data.guid,
                key: data.key,
                description: data.description,
                locStrings: Object.values(data.values)
                    .filter((value) => {
                        return Boolean(form.formState.dirtyFields.values?.[value.localeId]);
                    })
                    .map(({ value, isProtected, localeId }) => ({
                        isProtected,
                        value,
                        localeId,
                    })),
            };
            const { errors } = await updateTranslationMutation({ variables });
            if (!errors) {
                enqueueSnackbar("Translation saved", {
                    variant: "success",
                });
                modal.resolve({ baseTranslationChanged });
                return modal.hide();
            } else {
                console.dir(errors);
                enqueueSnackbar("Couldn't save translation", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [
            form.formState.dirtyFields.values,
            baseLocaleId,
            updateTranslationMutation,
            enqueueSnackbar,
            modal,
        ],
    );

    return (
        <Modal {...dialog} {...modalProtectionProps} size="xl">
            <FormProvider {...form}>
                <UpdateTranslationModalContext.Provider value={{ loading, baseLocaleValue }}>
                    <Form onSubmit={form.handleSubmit(onSubmit)}>
                        <Modal.Header className="justify-content-between">
                            <Modal.Title>Update translation</Modal.Title>
                            {form.formState.isDirty && (
                                <span className="small text-danger">
                                    The form has unsaved changes
                                </span>
                            )}
                        </Modal.Header>
                        <Modal.Body>
                            <Stack gap={4}>
                                <FormInput2<UpdateTranslationFormData>
                                    name="key"
                                    label="Key"
                                    rules={{
                                        min: 1,
                                        max: 255,
                                    }}
                                />
                                <FormInput2<UpdateTranslationFormData>
                                    name="description"
                                    label="Description"
                                    as="textarea"
                                    rules={{
                                        max: 1024,
                                    }}
                                />
                                <div>
                                    <UpdateWithDeeplButton gameId={props.gameId} />
                                </div>
                                <Row className="g-4">
                                    {props.locales.map((locale, index) => (
                                        <TranslationFieldContext.Provider
                                            value={{ index, locale }}
                                            key={locale.guid}
                                        >
                                            <Col md={6}>
                                                <UpdateTranslationField />
                                            </Col>
                                        </TranslationFieldContext.Provider>
                                    ))}
                                </Row>
                            </Stack>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={onCancel} variant="secondary">
                                Cancel
                            </Button>
                            <ProgressButton
                                type="submit"
                                disabled={!form.formState.isValid || loading}
                                loading={loading || form.formState.isSubmitting}
                            >
                                Save
                            </ProgressButton>
                        </Modal.Footer>
                    </Form>
                </UpdateTranslationModalContext.Provider>
            </FormProvider>
        </Modal>
    );
}

const UpdateTranslationModal = create<Props>(UpdateTranslationModalComponent);

export function useShowUpdateTranslationModal() {
    const { enqueueSnackbar } = useSnackbar();
    return useCallback(
        async (
            props: Omit<Props, "baseLocaleId">,
        ): Promise<{ baseTranslationChanged: boolean }> => {
            const baseLocale = props.locales.find((locale) => locale.isBaseLocale);

            if (baseLocale) {
                return show<{ baseTranslationChanged: boolean }, NiceModalHocProps & Props, Props>(
                    UpdateTranslationModal,
                    { ...props, baseLocaleId: baseLocale.guid },
                );
            } else {
                enqueueSnackbar("No base locale found", { variant: "error" });
                return { baseTranslationChanged: false };
            }
        },
        [enqueueSnackbar],
    );
}
