import { useCallback } from "react";
import { FaLock } from "react-icons/fa";
import { useFormContext } from "react-hook-form";
import { IconButton } from "../../../common/IconButton";
import { UpdateTranslationFormData } from "../../types";
import { useUpdateTranslationModalContext } from "../contexts/UpdateTranslationModalContext";
import { useTranslationFieldContext } from "../contexts/TranslationFieldContext";

export function ChangeProtectedFlagField() {
    const { locale } = useTranslationFieldContext();
    const { loading } = useUpdateTranslationModalContext();
    const form = useFormContext<UpdateTranslationFormData>();

    const fieldName = `values.${locale.guid}.isProtected` as const;
    const protectedState = form.watch(fieldName);
    const onChangeProtected = useCallback(() => {
        const currentValue = form.getValues(fieldName);
        form.setValue(fieldName, !currentValue, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
    }, [fieldName, form]);

    return (
        <IconButton
            className="d-inline-block"
            icon={FaLock}
            disabled={loading}
            variant={protectedState ? "primary" : "secondary"}
            onClick={onChangeProtected}
        />
    );
}
