import { useSnackbar } from "notistack";
import { useCallback, useMemo } from "react";
import {
    pointerWithin,
    DataRef,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Stack from "react-bootstrap/Stack";
import { AddTemplateToCategoryMutationVariables, Template, TemplateCategory } from "@/gql";
import { useAddTemplateToCategory, useCreateTemplateCategory } from "@/graphql/templates";
import { categoriesPageRoute } from "@/pages/CategoriesPage";
import { Category } from "@/components/categories/types";
import { useRenameCategoryModal } from "./components/RenameCategoryModal";
import { useTemplateCategoriesProvider } from "./providers/TemplateCategoriesProvider";
import { EditableTemplatesCategory } from "./components/EditableTemplatesCategory";

const EditableCategoriesGrid = styled.div`
    width: 100%;
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
`;

export function ManageCategories() {
    const { useParams } = categoriesPageRoute;
    const { gameId } = useParams();
    const { categories, moveTemplate, query } = useTemplateCategoriesProvider();
    const [addTemplateToCategory] = useAddTemplateToCategory({
        onCompleted: () => query.refetch(),
    });
    const { enqueueSnackbar } = useSnackbar();
    const [createTemplateCategory, { loading }] = useCreateTemplateCategory({
        onCompleted: () => {
            enqueueSnackbar("Category created", {
                variant: "success",
            });
            return query.refetch();
        },
    });
    const showRenameCategoryModal = useRenameCategoryModal();
    const onCreateCategory = useCallback(async () => {
        const result = await showRenameCategoryModal({});
        if (result !== null) {
            await createTemplateCategory({
                variables: {
                    gameId,
                    name: result.categoryName,
                },
            });
        }
    }, [showRenameCategoryModal, createTemplateCategory, gameId]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const handleDragEnd = useCallback(
        async (event: DragEndEvent) => {
            const { active, over } = event;
            const templateData = active.data as DataRef<Template>;
            const categoryData = over?.data as DataRef<TemplateCategory> | undefined;
            if (!categoryData?.current || !templateData.current) return;

            const templateId = templateData.current.guid;

            const canDrop = !categoryData.current.templates.find(
                (template) => template.guid === templateId,
            );

            if (!canDrop) return;

            const variables: AddTemplateToCategoryMutationVariables = {
                gameId: templateData.current.gameId,
                templateId: templateData.current.guid,
                categoryId: categoryData.current.guid,
            };
            moveTemplate({
                templateId: templateData.current.guid,
                newCategoryId: categoryData.current.guid,
            });

            await addTemplateToCategory({ variables });
            enqueueSnackbar("Template added to category", {
                variant: "success",
            });
        },
        [addTemplateToCategory, enqueueSnackbar, moveTemplate],
    );
    const categoriesColumns = useMemo(
        () =>
            categories.reduce(
                (acc, category) => {
                    if (category.name === "Unassigned") {
                        acc[1].push(category);
                        return acc;
                    } else {
                        acc[0].push(category);
                        return acc;
                    }
                },
                [[], []] as [Category[], Category[]],
            ),
        [categories],
    );

    return (
        <>
            <div className="mb-4">
                <Button disabled={loading} onClick={onCreateCategory}>
                    Create category
                </Button>
            </div>
            <EditableCategoriesGrid className="overflow-hidden">
                <DndContext
                    sensors={sensors}
                    collisionDetection={pointerWithin}
                    onDragEnd={handleDragEnd}
                >
                    <Stack gap={1} className="overflow-scroll">
                        {categoriesColumns[0].map((category) => (
                            <EditableTemplatesCategory key={category.guid} data={category} />
                        ))}
                    </Stack>
                    <Stack gap={1} className="overflow-scroll">
                        {categoriesColumns[1].map((category) => (
                            <EditableTemplatesCategory key={category.guid} data={category} />
                        ))}
                    </Stack>
                </DndContext>
            </EditableCategoriesGrid>
        </>
    );
}
