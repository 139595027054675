import { MdTranslate } from "react-icons/md";
import { useCallback } from "react";
import { useBulkUpdateFromDeeplMutation } from "@/graphql/translation";
import { useBulkUpdateFromDeepl } from "../modals/BulkUpdateFromDeepl";
import { useTranslationsPageContext } from "../TranslationsPageProvider";
import { ProgressButton } from "../../../common/ProgressButton";

export function BulkUpdateFromDeeplButton() {
    const bulkUpdateFromDeepl = useBulkUpdateFromDeepl();
    const [, { loading: bulkUpdateFromDeeplLoading }] = useBulkUpdateFromDeeplMutation();
    const { translations, gameId } = useTranslationsPageContext();
    const onBulkUpdateFromDeepl = useCallback(async () => {
        await bulkUpdateFromDeepl({ gameId });
        return translations.refetch();
    }, [bulkUpdateFromDeepl, gameId, translations]);

    return (
        <ProgressButton
            onClick={onBulkUpdateFromDeepl}
            loading={translations.loading || bulkUpdateFromDeeplLoading}
        >
            <MdTranslate /> Bulk update from Deepl
        </ProgressButton>
    );
}
