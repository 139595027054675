import { ChangeEvent, useEffect, useMemo, useRef } from "react";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from "react-bootstrap/Form";
import { useDebounceCallback } from "usehooks-ts";
import { createColumnHelper } from "@tanstack/react-table";
import { TranslationsQuery } from "@/gql";
import { DataTable2 } from "@/components/common/DataTable2";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { TranslationPageLayoutNav } from "@/pages/translation/TranslationPageLayout";
import { TranslationData, TranslationDataValuesMap } from "@/components/translation/types";
import { ImportButton } from "./components/ImportButton";
import { ExportButton } from "./components/ExportButton";
import { CreateTranslationButton } from "./components/CreateTranslationButton";
import { BulkUpdateFromDeeplButton } from "./components/BulkUpdateFromDeeplButton";
import { CreateFromDeeplTranslationButton } from "./components/CreateFromDeeplTranslationButton";
import { TranslationCell } from "./components/TranslationCell";
import { TranslationCellHeader } from "./components/TranslationCellHeader";
import { useTranslationsPageContext } from "./TranslationsPageProvider";
import { ActionsColumnCell } from "./components/ActionsColumnCell";
import { TranslationKeyCell } from "./components/TranslationKeyCell";
import { TranslationDescriptionCell } from "./components/TranslationDescriptionCell";
import { TablePagination } from "./components/TablePagination";

type Props = {
    gameId: string;
    data: TranslationsQuery;
    total: number;
};

const columnHelper = createColumnHelper<TranslationData>();

export function TranslationsTable(props: Props) {
    const { translations, pagination } = useTranslationsPageContext();
    const localesMap = useMemo(() => keyBy(props.data.locales, "guid"), [props.data.locales]);
    const filterInputRef = useRef<HTMLInputElement | null>(null);
    const onQueryChange = useDebounceCallback((e: ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;

        if (pagination.query !== newQuery) {
            pagination.setQuery(newQuery);
        }
    }, 1000);
    const data = useMemo<TranslationData[]>(
        () =>
            props.data.translations.map((translation) => ({
                guid: translation.guid,
                gameId: translation.gameId,
                key: translation.key,
                description: translation.description,
                values: translation.translationStrings.reduce((acc, value) => {
                    const locKey = localesMap[value.localeId]?.code;

                    if (locKey !== undefined) {
                        acc[locKey] = {
                            value: value.value,
                            isProtected: value.isProtected,
                        };
                    }

                    return acc;
                }, {} as TranslationDataValuesMap),
            })),
        [localesMap, props.data.translations],
    );
    const columns = useMemo(
        () => [
            columnHelper.accessor("guid", {
                header: "Actions",
                cell: ActionsColumnCell,
                size: 100,
            }),
            columnHelper.accessor("key", {
                header: "Key",
                cell: TranslationKeyCell,
                size: 200,
            }),
            columnHelper.accessor("description", {
                header: "Description",
                cell: TranslationDescriptionCell,
                minSize: 350,
            }),
            ...orderBy(props.data.locales, (v) => !v.isBaseLocale).map((locale) =>
                columnHelper.accessor(`values.${locale.code}`, {
                    header: () => <TranslationCellHeader locale={locale} />,
                    cell: (props) => <TranslationCell {...props} locale={locale} />,
                    size: 300,
                }),
            ),
        ],
        [props.data.locales],
    );

    useEffect(() => {
        filterInputRef.current?.focus();
    }, []);

    return (
        <>
            <div className="d-flex justify-content-between">
                <TranslationPageLayoutNav />
                <ButtonGroup>
                    <ExportButton />
                    <ImportButton />
                    <CreateTranslationButton />
                    <BulkUpdateFromDeeplButton />
                    <CreateFromDeeplTranslationButton />
                </ButtonGroup>
            </div>
            <Form.Control
                ref={filterInputRef}
                defaultValue={pagination.query}
                placeholder="Filter"
                onChange={onQueryChange}
            />
            <DataAwaiter2 {...translations}>
                {(translationsData) => (
                    <>
                        <DataTable2 data={data} columns={columns} />
                        <TablePagination
                            {...pagination}
                            total={translationsData.translationsTotal}
                        />
                    </>
                )}
            </DataAwaiter2>
        </>
    );
}
