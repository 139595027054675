import Button from "react-bootstrap/Button";
import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { DeployEventInterface } from "@/gql";
import { DeployResults } from "@/components/common/Deploy/components/DeployResults";

type Props = {
    spreadsheetId: string;
    warnings?: DeployEventInterface[];
};

const ExportResultModal = create(function ExportResultModal(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${props.spreadsheetId}/edit#gid=0`;

    return (
        <Modal {...dialog} size="xl">
            <Modal.Body>
                <h3>Template data has been exported successfully</h3>
                {props.warnings && props.warnings.length > 0 && (
                    <>
                        <p className="mb-1">There are some possible warnings</p>
                        <DeployResults results={props.warnings} className="mb-3" />
                    </>
                )}
                <p>You can see the file by clicking the button below</p>
                <Button as="a" href={spreadsheetUrl} target="_blank">
                    Open spreadsheet
                </Button>
            </Modal.Body>
        </Modal>
    );
});

export function useExportResultModal() {
    return useCallback((props: Props) => show(ExportResultModal, props), []);
}
