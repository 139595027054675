import { useReducer } from "react";
import { Category } from "../types";

type State = {
    categories: Category[];
};

export enum ReducerAction {
    SetCollection = "SetCollection",
    MoveTemplate = "MoveTemplate",
    DeleteCategory = "DeleteCategory",
    RenameCategory = "RenameCategory",
}

export type SetCollectionAction = { type: ReducerAction.SetCollection; categories: Category[] };
export type MoveTemplateAction = {
    type: ReducerAction.MoveTemplate;
    templateId: string;
    newCategoryId: string;
};
export type DeleteCategoryAction = { type: ReducerAction.DeleteCategory; categoryId: string };
export type RenameCategoryAction = {
    type: ReducerAction.RenameCategory;
    categoryId: string;
    newName: string;
};

export type ActionWithoutType<T extends { type: ReducerAction }> = Omit<T, "type">;

type Action =
    | SetCollectionAction
    | MoveTemplateAction
    | DeleteCategoryAction
    | RenameCategoryAction;

function setCollection(state: State, action: SetCollectionAction): State {
    return { categories: action.categories };
}

function moveTemplate(state: State, action: MoveTemplateAction): State {
    const { templateId, newCategoryId } = action;
    const oldCategory = state.categories.find(
        (c) => c.templates.findIndex((t) => t.guid === templateId) !== -1,
    );

    if (!oldCategory) {
        console.error(`old category of template ${templateId} not found`);
        return state;
    }

    const template = oldCategory.templates.find((t) => t.guid === templateId);

    if (!template) {
        console.error(`template ${templateId} not found`);
        return state;
    }

    return {
        categories: state.categories.map((c) => {
            if (c.guid === oldCategory.guid) {
                return { ...c, templates: c.templates.filter((t) => t.guid !== templateId) };
            } else if (c.guid === newCategoryId) {
                // Помещаем шаблон в новую
                return { ...c, templates: [...c.templates, template] };
            } else {
                return c;
            }
        }),
    };
}

function deleteCategory(state: State, action: DeleteCategoryAction): State {
    return {
        categories: state.categories.filter((c) => c.guid !== action.categoryId),
    };
}

function renameCategory(state: State, action: RenameCategoryAction): State {
    return {
        categories: state.categories.map((c) =>
            c.guid !== action.categoryId
                ? c
                : {
                      ...c,
                      name: action.newName,
                  },
        ),
    };
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ReducerAction.SetCollection:
            return setCollection(state, action);
        case ReducerAction.MoveTemplate:
            return moveTemplate(state, action);
        case ReducerAction.DeleteCategory:
            return deleteCategory(state, action);
        case ReducerAction.RenameCategory:
            return renameCategory(state, action);
        default:
            return state;
    }
}

export function useTemplateCategoriesReducer(categories: Category[]) {
    return useReducer<typeof reducer>(reducer, { categories });
}
