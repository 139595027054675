import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { useFormContext } from "react-hook-form";
import { useGetTranslationsFromDeepl } from "@/graphql/translation";
import { ProgressButton } from "@/components/common/ProgressButton";
import { UpdateTranslationFormData } from "@/components/translation/types";
import { useUpdateTranslationModalContext } from "../contexts/UpdateTranslationModalContext";

type Props = {
    gameId: string;
};

export function UpdateWithDeeplButton(props: Props) {
    const { gameId } = props;
    const form = useFormContext<UpdateTranslationFormData>();
    const [getTranslationsFromDeepl, { loading: getTranslationsFromDeeplLoading }] =
        useGetTranslationsFromDeepl();
    const { enqueueSnackbar } = useSnackbar();
    const { loading: contextLoading, baseLocaleValue } = useUpdateTranslationModalContext();
    const loading = getTranslationsFromDeeplLoading || contextLoading;
    const onUpdateWithDeepl = useCallback(async () => {
        const result = await getTranslationsFromDeepl({
            variables: {
                gameId,
                value: baseLocaleValue,
            },
        });

        if (!result.data?.getTranslationsFromDeepl) {
            enqueueSnackbar("Couldn't update translations", { variant: "error" });
            return;
        }

        result.data.getTranslationsFromDeepl.forEach((value) => {
            form.setValue(`values.${value.localeId}.value`, value.value);
        });
    }, [baseLocaleValue, enqueueSnackbar, form, gameId, getTranslationsFromDeepl]);

    return (
        <ProgressButton variant="secondary" onClick={onUpdateWithDeepl} loading={loading}>
            Update with deepl
        </ProgressButton>
    );
}
