import { DeployEventInterface, DeployStep } from "@/gql";
import { MissingReferenceRenderer } from "@/components/common/Deploy/components/MessageRenderer/MissingReferenceRenderer";
import { MissingReferencedEntityEventRenderer } from "@/components/common/Deploy/components/MessageRenderer/MissingReferencedEntityEventRenderer";
import { VersionsFileGenerationEventRenderer } from "./VersionsFileGenerationEventRenderer";
import { ArtifactEventRenderer } from "./ArtifactEventRenderer";
import { DeploymentValidationEventRenderer } from "./DeploymentValidationEventRenderer";
import { VersionsFileUploadEventRenderer } from "./VersionsFileUploadEventRenderer";
import { NoTemplatesEventRenderer } from "./NoTemplatesEventRenderer";
import { ConsumerProcessingEventRenderer } from "./ConsumerProcessingEventRenderer";
import { DeployEventRenderer } from "./DeployEventRenderer";
import { EntityEventRenderer } from "./EntityEventRenderer";
import { ManifestGenerationEventRenderer } from "./ManifestGenerationEventRenderer";
import { IncorrectValueEventRenderer } from "./IncorrectValueEventRenderer";
import { NotExportableEventRenderer } from "./NotExportableEventRenderer";
import { RequiredCheckFailedEventRenderer } from "./RequiredCheckFailedEventRenderer";
import { TemplateValidationEventRenderer } from "./TemplateValidationEventRenderer";
import { UniqCheckFailedEventRenderer } from "./UniqCheckFailedEventRenderer";

interface MessageRendererProps<E extends DeployEventInterface> {
    event: DeployEventInterface & E;
    gameId: string;
}

export function MessageRenderer(props: MessageRendererProps<any>) {
    const { event } = props;
    switch (event.deployCode) {
        case DeployStep.ConsumerProcessing:
        case DeployStep.ConsumerProcessingFailed:
        case DeployStep.ConsumerProcessingFinished:
            return <ConsumerProcessingEventRenderer event={event} />;
        case DeployStep.DeployFailed:
        case DeployStep.DeployFinished:
        case DeployStep.DeployStarted:
            return <DeployEventRenderer event={event} />;
        case DeployStep.EntityUpload:
        case DeployStep.EntityUploadFailed:
        case DeployStep.EntityUploadFinished:
            return <EntityEventRenderer event={event} />;
        case DeployStep.IncorrectValue:
            return <IncorrectValueEventRenderer event={event} />;
        case DeployStep.ManifestGeneration:
        case DeployStep.ManifestGenerationFailed:
        case DeployStep.ManifestGenerationFinished:
            return <ManifestGenerationEventRenderer event={event} />;
        case DeployStep.NotExportable:
            return <NotExportableEventRenderer event={event} />;
        case DeployStep.RequiredCheckFail:
            return <RequiredCheckFailedEventRenderer event={event} />;
        case DeployStep.TemplateValidation:
        case DeployStep.TemplateValidationError:
        case DeployStep.TemplateValidationFailed:
        case DeployStep.TemplateValidationMissingReference:
            return <TemplateValidationEventRenderer event={event} gameId={props.gameId} />;
        case DeployStep.UniqCheckFail:
            return <UniqCheckFailedEventRenderer event={event} />;
        case DeployStep.NoTemplates:
            return <NoTemplatesEventRenderer event={event} />;
        case DeployStep.VersionsFileUpload:
        case DeployStep.VersionsFileUploadFinished:
        case DeployStep.VersionsFileUploadFailed:
            return <VersionsFileUploadEventRenderer event={event} />;
        case DeployStep.VersionsFileGeneration:
        case DeployStep.VersionsFileGenerationFailed:
        case DeployStep.VersionsFileGenerationFinished:
            return <VersionsFileGenerationEventRenderer event={event} />;
        case DeployStep.DeploymentValidation:
        case DeployStep.DeploymentValidationFailed:
        case DeployStep.DeploymentValidationError:
            return <DeploymentValidationEventRenderer event={event} />;
        case DeployStep.OrphanTemplateParam:
            return <span>Orphan template parameter found</span>;
        case DeployStep.CircularReference:
            return (
                <>
                    <span>Circular reference detected</span>
                    <p>{event.referenceChain.join(" -> ")}</p>
                </>
            );
        case DeployStep.ArtifactDeployment:
            return (
                <>
                    <span>Deploying artifact</span>
                </>
            );
        case DeployStep.ArtifactGenerationStarted:
        case DeployStep.ArtifactGenerationFailed:
        case DeployStep.ArtifactGenerationFinished:
            return <ArtifactEventRenderer event={event} />;
        case DeployStep.MissingReferencedEntity:
            return <MissingReferencedEntityEventRenderer event={event} />;
        case DeployStep.MissingReference:
            return <MissingReferenceRenderer event={event} />;
        case DeployStep.Unknown:
            return <span>Unknown error</span>;
        default:
            return <span className="fst-italic">Unknown event</span>;
    }
}
