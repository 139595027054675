import Nav from "react-bootstrap/Nav";
import { Outlet, createRoute } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import { FaCog, FaEye, FaFileExport, FaFileImport, FaList, FaTable } from "react-icons/fa";
import { NavItem } from "@/components/common/NavItem";
import { TemplateType } from "@/gql";
import { useAppSelector } from "@/hooks";
import { gameDetailsSelector } from "@/store/games/selectors";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { entitiesListRoute } from "@/pages/EntitiesList";
import { templateStructureRoute } from "@/pages/template/TemplateStructure";
import { templateParametersRoute } from "@/pages/template/TemplateParameters";
import { templateDisplayRoute } from "@/pages/template/TemplateDisplay";
import { exportTemplateToSpreadsheetRoute } from "@/pages/template/TemplateExportToSpreadsheet";
import { importTemplateFromSpreadsheetRoute } from "@/pages/template/TemplateImportFromSpreadsheet";
import { TemplateProvider } from "@/pages/template/TemplateProvider";

export function Template() {
    const { name: gameName } = useAppSelector(gameDetailsSelector);

    return (
        <TemplateProvider>
            {(data) => {
                const isIC = data.template.type === TemplateType.InjectedComponent;
                return (
                    <>
                        <Helmet
                            titleTemplate={`${data.template.name} - %s - ${gameName}`}
                            defaultTitle={`${data.template.name} - ${gameName}`}
                        />
                        <Nav variant="pills" defaultActiveKey="/home">
                            <NavItem to={entitiesListRoute.to}>
                                <FaTable /> Entities
                            </NavItem>
                            <NavItem to={templateStructureRoute.to}>
                                <FaCog /> Settings
                            </NavItem>
                            <NavItem to={templateParametersRoute.to}>
                                <FaList /> Template parameters
                            </NavItem>
                            <NavItem to={templateDisplayRoute.to}>
                                <FaEye /> Display
                            </NavItem>
                            <NavItem to={exportTemplateToSpreadsheetRoute.to}>
                                <FaFileImport /> Export to spreadsheet
                            </NavItem>
                            {!isIC && (
                                <NavItem to={importTemplateFromSpreadsheetRoute.to}>
                                    <FaFileExport /> Import from spreadsheet
                                </NavItem>
                            )}
                        </Nav>
                        <Outlet />
                    </>
                );
            }}
        </TemplateProvider>
    );
}

export const templatePageRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "templates/$templateId",
    component: Template,
});
