import { createRoute } from "@tanstack/react-router";
import { Helmet } from "react-helmet-async";
import { Permission } from "@/gql";
import { withRBAC } from "@/components/rbac";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { ManageCategories } from "@/components/categories/ManageCategories";
import { TemplateCategoriesProvider } from "@/components/categories/providers/TemplateCategoriesProvider";

const CategoriesPage = withRBAC(
    function CategoriesPage() {
        const { useParams } = categoriesPageRoute;
        const { gameId } = useParams();

        return (
            <>
                <Helmet title="Categories" />
                <TemplateCategoriesProvider gameId={gameId}>
                    <ManageCategories />
                </TemplateCategoriesProvider>
            </>
        );
    },
    {
        requiredPermissions: [Permission.StructureRead, Permission.StructureWrite],
    },
);

export default CategoriesPage;
export const categoriesPageRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "categories",
    component: CategoriesPage,
});
