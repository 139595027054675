import { gql } from "@apollo/client";
import { makeGqlMutationHook } from "@/graphql/util";
import { UpdateTranslationStringsMutation, UpdateTranslationStringsMutationVariables } from "@/gql";
import { TRANSLATION_FRAGMENT } from "@/graphql/fragments/translation.fragment";

const SCHEMA = gql`
    mutation updateTranslationStrings(
        $gameId: String!
        $locStrings: [UpdateTranslationStringsDto!]
    ) {
        updateTranslationStrings(gameId: $gameId, locStrings: $locStrings) {
            guid
            isProtected
            value
            localeId
            translationId
        }
    }
`;

export const useUpdateLocalizationStringsMutation = makeGqlMutationHook<
    UpdateTranslationStringsMutation,
    UpdateTranslationStringsMutationVariables
>(SCHEMA);
