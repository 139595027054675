import cn from "classnames";
import { FaStar, FaThumbtack } from "react-icons/fa";
import { MouseEventHandler, useCallback } from "react";
import { Template } from "@/types";
import { IconButton } from "../../common/IconButton";
import { CategoryTemplate } from "./CategoryTemplate";

type Props = {
    template: Template;
    className?: string;
    onItemSelected?: () => void;
    isPinned?: boolean;
    onPin?: () => void;
    isFavorite?: boolean;
    onFavorite?: () => void;
};

export function SidebarCategoryTemplate(props: Props) {
    return (
        <CategoryTemplate
            template={props.template}
            className={props.className}
            start={
                <>
                    {props.onFavorite && (
                        <IconButton
                            size="sm"
                            className={cn({
                                "text-yellow": props.isFavorite,
                            })}
                            onClick={props.onFavorite}
                            icon={FaStar}
                            width={1.5}
                        />
                    )}
                    {props.onPin && (
                        <IconButton
                            size="sm"
                            className={cn({
                                "text-yellow": props.isPinned,
                            })}
                            active={props.isPinned}
                            onClick={props.onPin}
                            icon={FaThumbtack}
                            width={1.5}
                        />
                    )}
                </>
            }
        />
    );
}
