import { PropsWithChildren, ReactElement, useState } from "react";
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    from,
    InMemoryCache,
    split,
    ServerError,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import { useNavigate } from "@tanstack/react-router";
import { useSnackbar } from "notistack";
import throttle from "lodash/throttle";
import { RetryLink } from "@apollo/client/link/retry";

export function SetupGraphql({ children }: PropsWithChildren): ReactElement {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [errorLink] = useState(() =>
        onError(({ graphQLErrors, operation, forward, networkError }) => {
            if (graphQLErrors) {
                for (const error of graphQLErrors) {
                    switch (error.extensions.code) {
                        case "UNAUTHENTICATED":
                            navigate({ to: "/login" });
                            return forward(operation);
                    }
                }
            }

            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
    );
    const [client] = useState(() => {
        const showNotification = throttle(enqueueSnackbar, 1000);

        return new ApolloClient({
            cache: new InMemoryCache(),
            queryDeduplication: true,
            link: split(
                ({ query }) => {
                    const definition = getMainDefinition(query);
                    return (
                        definition.kind === "OperationDefinition" &&
                        definition.operation === "subscription"
                    );
                },
                new GraphQLWsLink(
                    createClient({
                        url: window.env.apiSubscriptionUrl,
                        // connectionParams: () => {
                        //     const token = localStorage.getItem("token");
                        //     return {
                        //         Authorization: token ? `Bearer ${token}` : "",
                        //     };
                        // },
                        shouldRetry: () => {
                            showNotification("Attempting to reconnect", { variant: "error" });
                            return true;
                        },
                        retryAttempts: Infinity,
                        retryWait: (retries) =>
                            new Promise((resolve) =>
                                setTimeout(resolve, (retries + 1) * Math.E * 1000),
                            ),
                    }),
                ),
                from([
                    errorLink,
                    new RetryLink({
                        delay: {
                            initial: Math.E * 1000,
                            max: Infinity,
                            jitter: true,
                        },
                        attempts: {
                            max: 3,
                            retryIf: (error) => {
                                if ("name" in error && error.name === "ServerError") {
                                    if (
                                        (error as ServerError).response.status >= 400 &&
                                        (error as ServerError).response.status < 500
                                    ) {
                                        return false;
                                    }
                                }
                                return Boolean(error);
                            },
                        },
                    }),
                    createHttpLink({
                        uri: window.env.apiUrl,
                        credentials: "include",
                    }),
                ]),
            ),
        });
    });

    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
