import { useCallback, useMemo } from "react";
import { useForm, useController } from "react-hook-form";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { Link, useNavigate } from "@tanstack/react-router";
import cn from "classnames";
import { uuidValidator } from "@/utils/validators/uuid";
import { entityPageRoute } from "@/pages/EntityPage";

type FormData = {
    uuid: string;
};

type Props = {
    gameId: string;
};

export function SearchPromptModalComponent(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const navigate = useNavigate();
    const form = useForm<FormData>({
        defaultValues: { uuid: "" },
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const onSubmit = useCallback(
        (data: FormData) => {
            modal.resolve(data);
            modal.hide();
            return navigate({
                to: entityPageRoute.to,
                from: "/",
                params: {
                    gameId: props.gameId,
                    entityId: data.uuid,
                },
            });
        },
        [modal, navigate, props.gameId],
    );
    const uuidController = useController({
        name: "uuid",
        control: form.control,
        rules: {
            required: { value: true, message: "This value is required" },
            validate(value: string) {
                const result = uuidValidator().safeParse(value);

                if (!result.success) {
                    return result.error.issues.map((i) => i.message).join("\n");
                }
            },
        },
    });

    return (
        <Modal backdrop keyboard {...dialog}>
            <Modal.Header>
                <Modal.Title>Find entity by id</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack as={Form} gap={2} onSubmit={form.handleSubmit(onSubmit)}>
                    <Form.Group>
                        <Form.Control
                            placeholder="Search..."
                            {...uuidController.field}
                            isInvalid={uuidController.fieldState.invalid}
                        />
                        {uuidController.fieldState.error && (
                            <Form.Control.Feedback type="invalid">
                                {uuidController.fieldState.error.message}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <Link
                            className={cn("btn btn-link", { disabled: !form.formState.isValid })}
                            target="-_blank"
                            to={entityPageRoute.to}
                            params={{ gameId: props.gameId, entityId: form.watch("uuid") }}
                            disabled={!form.formState.isValid}
                        >
                            Open in new tab
                        </Link>
                        <Button type="submit" disabled={!form.formState.isValid}>
                            Search
                        </Button>
                    </div>
                </Stack>
            </Modal.Body>
        </Modal>
    );
}

const SearchPromptModal = create(SearchPromptModalComponent);

export function useSearchPromptModal() {
    return useCallback(async (props: Props) => {
        return show<FormData, NiceModalHocProps & Props, Props>(SearchPromptModal, props);
    }, []);
}
